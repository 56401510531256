import React, { useEffect, useState } from 'react'

import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Button, Select, FormText } from 'src/components'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import { haveAnyValue } from 'src/utils/utilitiesV2'

import { CardProviderEnum, CardProviderOptions } from 'src/enums/CardProviderEnum'

interface ICardProviderFormProps {
  show: boolean
  id?: number
  provider?: ICardProviderDTO
  onClose: () => void
  onChange: (connection: ICardProviderConnection) => void
}

export const CardProviderForm = ({
  show,
  id,
  provider,
  onClose,
  onChange,
}: ICardProviderFormProps) => {
  const [data, setData] = useState<ICardProviderDTO>({})

  const isCreate = !haveAnyValue(id)

  const disabled = !isCreate

  const disabledConnection: boolean =
    !haveAnyValue(data?.provider?.value) || !haveAnyValue(data.authentication)

  useEffect(() => {
    if (!(show && haveAnyValue(id))) return
    setData(provider)
  }, [show, id])

  const onHide = () => {
    onClear()
    onClose()
  }

  const onClear = () => setData({})

  const onClick = () => {
    onChange({
      providerId: data.provider.value,
      authentication: data.authentication,
    })
    onClear()
  }

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Proveedor de tarjetas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xl={12}>
              <Select
                label={'Proveedor'}
                required
                options={CardProviderOptions}
                value={data.provider}
                placeholder={'Selecciona un proveedor'}
                onChange={(provider: ISelectNumber) => setData({ ...data, provider })}
                disabled={disabled}
              />
            </Col>

            {data.provider?.value === CardProviderEnum.PAGALO && (
              <Col xl={12}>
                <FormText
                  label={'Autenticación'}
                  value={data.authentication}
                  required
                  changeValue={(authentication: string) =>
                    setData({ ...data, authentication })
                  }
                  disabled={disabled}
                />
              </Col>
            )}
          </Row>
        </Form>
      </Modal.Body>
      {isCreate && (
        <Modal.Footer className={'container-buttons'}>
          <Button icon={faCheck} disabled={disabledConnection} onClick={onClick}>
            Conectar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
