import { PolicyType } from 'src/content/Products/Policies/PolicyType'

export const findPolicy = (
  policies: IDiscountPolicies[],
  quantity: number,
): IDiscountPolicies => {
  return policies.find(({ min, max }) => quantity >= min && quantity <= max)
}

export const findPolicyByPrice = (
  policies: IDiscountPolicies[],
  price: number,
): IDiscountPolicies => {
  if (policies?.length === 0) return null
  return policies.find(({ amount }) => amount === price)
}

export const calculateNewDiscount = (
  policy: IDiscountPolicies,
  total: number,
  quantity: number,
): number => {
  if (policy && policy.type === PolicyType.DISCOUNT) {
    const { amount, percentage } = policy
    let value: number
    if (percentage) {
      value = (amount / 100) * total
    } else value = quantity * amount

    return Number(value.toFixed(2))
  }
  return 0
}

interface IInitialPolicyResponse {
  withPolicy: boolean
  type?: PolicyType
  price?: IPriceSelected
  discount?: number
}
export const setInitialPolicy = (
  policies: IDiscountPolicies[],
  amount: number,
  quantity: number,
  defaultPrice: number,
): IInitialPolicyResponse => {
  const response: IInitialPolicyResponse = {
    withPolicy: false,
    price: {
      label: defaultPrice.toString(),
      alias: 'Default',
      value: defaultPrice,
    },
  }

  const policy = findPolicy(policies, quantity)
  if (policy) {
    response.withPolicy = true
    if (policy.type === PolicyType.PRICE) {
      const alias = `Entre ${policy.min} y ${policy.max}`
      response.type = PolicyType.PRICE
      response.price = {
        label: policy.amount.toString(),
        alias,
        value: policy.amount,
      }
      return response
    }
    response.type = PolicyType.DISCOUNT
    response.discount = calculateNewDiscount(policy, quantity * defaultPrice, quantity)
    return response
  }
  return response
}
