export enum CardProviderEnum {
  PAGALO = 1,
}

export const CardProviderName = {
  [CardProviderEnum.PAGALO]: 'PAGALO',
}

export const CardProviderOptions: ISelectNumber[] = [
  { value: CardProviderEnum.PAGALO, label: CardProviderName[CardProviderEnum.PAGALO] },
]
