import React from 'react'
import { useSelector } from 'react-redux'

import { MultipleSelect } from 'src/components/custom/MutipleSelects/MultipleSelect'
import { selectUsers } from 'src/selectors/modules.selector'

interface SelectUsersProps {
  label: string
  onChange: (users: ISelect[]) => void
}
const SelectUsers = ({ label, onChange }: SelectUsersProps) => {
  const users: IUser[] = useSelector(selectUsers)

  return <MultipleSelect options={users} label={label} onChange={onChange} />
}
export default SelectUsers
