import React, { useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ListCategories, Select } from 'src/components/index'

interface MultipleSelectProps {
  options: ISelect[]
  label: string
  onChange: (values: ISelect[]) => void
}

export const MultipleSelect = ({ options, label, onChange }: MultipleSelectProps) => {
  const [items, setItems] = useState<ISelect[]>([])

  const onRemove = (item: ISelect) => {
    const index = items.findIndex(i => i.value === item.value)
    const newItems = [...items]
    newItems.splice(index, 1)
    onCallBack(newItems)
  }

  const onCallBack = (response: ISelect[]) => {
    setItems(response)
    onChange(response)
  }

  const filteredItems = useMemo(() => {
    return options.filter(u => items.findIndex(i => i.value === u.value) === -1)
  }, [items, options])

  return (
    <Row>
      <Col xl={12}>
        <Select
          label={label}
          options={filteredItems}
          value={{ label: 'Selecciona una opción', value: null }}
          onChange={(option: ISelect) => {
            const newItems = [...items, option]
            onCallBack(newItems)
          }}
        />
      </Col>

      <Col xl={12}>
        <ListCategories items={items} onRemove={onRemove} />
      </Col>
    </Row>
  )
}
