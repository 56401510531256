import { actions } from 'src/actions/cardProvider.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState: CardProviderState = {
  providers: [],
  provider: { id: 0, name: 'undefined' },
  cardLinkResponse: { url: '', date: 0 },
  cardVoucherResponse: { status: 'default' },
  linkRequest: { documentId: undefined, documentCode: undefined },
  seeCardPaymentStatus: { documentId: undefined, documentCode: undefined },
}

const CardProviderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case successState(actions.GET_CARD_PAYMENT_VOUCHER):
      return { ...state, cardVoucherResponse: extract(payload, {}) }
    case successState(actions.SET_CARD_PAYMENT_STATUS):
      return {
        ...state,
        seeCardPaymentStatus: extract(payload, {
          documentId: undefined,
          documentCode: undefined,
        }),
      }
    case successState(actions.SET_LINK_PAYMENT_REQUEST):
      return {
        ...state,
        linkRequest: extract(payload, { documentId: undefined, documentCode: undefined }),
      }
    case successState(actions.GET_LINK_PAYMENT_VALUES):
      return { ...state, cardVoucherResponse: extract(payload, {}) }
    case successState(actions.CREATE_LINK_PAYMENT):
      return { ...state, cardLinkResponse: extract(payload, {}) }
    case successState(actions.GET_CARD_PROVIDERS):
      return { ...state, providers: extract(payload, []) }
    case successState(actions.GET_CARD_PROVIDER):
      return { ...state, provider: extract(payload, {}) }
    default:
      return state
  }
}
export default CardProviderReducer
