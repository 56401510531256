import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card, Paragraph } from 'src/components'

interface CardVoucherProps {
  data: ISelectString[]
  date: string
}
export const CardVoucher = ({ data, date }: CardVoucherProps) => {
  return (
    <Card title={`Fecha y hora de pago: ${date}`}>
      {data.map(item => (
        <Row key={item.label}>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <Paragraph bold>{item.label}</Paragraph>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12} className={'justify-content-end'}>
            <Paragraph>{item.value}</Paragraph>
          </Col>
        </Row>
      ))}
    </Card>
  )
}
