import React from 'react'
import { useElapsedTime } from 'src/components/Time/hooks/useElapsedTime'
import { Paragraph } from 'src/components/index'
import { formatHourFromMillis } from 'src/utils/formatters'

interface ElapsedTimeProps {
  start: number
  finish?: number
}

export const ElapsedTime = ({ start, finish }: ElapsedTimeProps) => {
  const time = useElapsedTime(start, finish)
  return <Paragraph>Transcurrido: {formatHourFromMillis(time, true)}</Paragraph>
}
