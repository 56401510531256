import moment from 'moment'
import { toMoney } from './utilities'
import { countDecimals, haveAnyValue } from 'src/utils/utilitiesV2'

moment.locale('es', {
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_',
    ),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos pocos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
})

const GuatemalaTimeZoneOffset = 6

const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
]

export const formatNumberWithCommas = (x, toFixed, customDecimals = false) => {
  let response = '0'
  if (x !== null || !typeof x !== undefined) {
    x = parseFloat(x)
    if (customDecimals) toFixed = countDecimals(x)

    if (toFixed !== null && toFixed !== undefined) x = x.toFixed(toFixed)
    else x = x.toFixed(2)

    x = String(x)

    let values = x.split('.')
    let decimals = '00'

    if (values.length > 1) decimals = values[1]
    let entire = values[0]

    response = parseFloat(entire)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (toFixed !== 0) {
      response += '.'.concat(decimals)
    }
  }
  return response
}

export const pad = x => x.toString().padStart(2, '0')

export const formatDateFromMillis = (millis, plusHour, withoutLT) => {
  if (!haveAnyValue(millis)) return 'Sin fecha'

  const formatter = `DD/MM/YYYY${!withoutLT ? ' LT' : ''}`
  let current = moment(millis)

  if (plusHour) current = current.add(plusHour, 'h')

  return current.format(formatter)
}

export const formatUtcDateFromMillis = (millis, plusHour, withoutLT) => {
  const formatter = `DD/MM/YYYY${!withoutLT ? ' LT' : ''}`
  let current = moment(millis).utcOffset(-6)

  if (plusHour) current = current.add(plusHour, 'h')

  return current.format(formatter)
}

export const formatDateToNameFromMillis = millis => {
  return moment(millis).format('LL')
}

export const formatHourFromMillis = (millis, seconds) => {
  const date = new Date(0)
  date.setUTCMilliseconds(millis)
  return `${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}${
    seconds ? ':' + pad(date.getUTCSeconds()) : ''
  }`
}

/** Convert milliseconds to days
 * @function
 * @return string
 * */
export const formatDaysFromMillis = (millis, withNegative) => {
  const days = Math.ceil(millis / (1000 * 3600 * 24))
  return days >= 0 ? days : withNegative ? days : '0'
}

export const formatShortDateFromMillis = millis => {
  const date = new Date(0)
  date.setUTCMilliseconds(millis)
  return `${pad(date.getUTCDate())} ${months[date.getUTCMonth()]}`
}

export const formatDurationFromSeconds = seconds => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60)
  seconds = seconds % 60
  return `${hours ? hours + 'h ' : ''}${minutes ? minutes + 'm ' : ''}${
    seconds < 10 ? '0' : ''
  }${seconds}s`
}

export const getStatusLastDay = (date, range) => {
  const day = moment(date).utc(true).utcOffset(GuatemalaTimeZoneOffset).unix()
  return moment.unix(day).isBetween(moment().subtract(range, 'd'), moment())
}

export const getNumber = numb => {
  let response = parseFloat(numb).toFixed(2)
  return parseFloat(response)
}

export const getItemValue = (item, param, type, yes, no, custom, decimals) => {
  //[statusData, name]
  if (item && param) {
    if (custom) return custom(item)
    let value = item[param[0]]
    if (value instanceof Object) return getItemValue(value, param.slice(1), type) //[name]
    else if (type === 'text') return value || '---'
    else if (type === 'code') return `No. ${value || '---'}`
    else if (type === 'number') return toMoney(value, true, decimals)
    else if (type === 'currency') return toMoney(value)
    else if (type === 'date') return value ? formatDateFromMillis(value) : '---'
    else if (type === 'boolean') return value ? yes : no
    else if (type === 'measure') return value + ' unidades'
    else if (type === 'nit') return value || 'CF'
    else if (type === 'nac') return value || 'N/A'
    else if (type === 'available') return value ? 'Disponible' : 'Ocupada'
    else if (type === 'yesOrNo') return value ? 'Si' : 'No'
    else if (type === 'wholeNumber') return value || 0
    else if (type === 'percentage') return `${toMoney(value, true, decimals)}%`
    else if (type === 'custom') return value
    else return '---'
  }
  return '---'
}

export const formatNumberToWords = number => {
  const Unidades = number => {
    switch (number) {
      case 1:
        return 'un'
      case 2:
        return 'dos'
      case 3:
        return 'tres'
      case 4:
        return 'cuatro'
      case 5:
        return 'cinco'
      case 6:
        return 'seis'
      case 7:
        return 'siete'
      case 8:
        return 'ocho'
      case 9:
        return 'nueve'
      default:
        return ''
    }
  }

  const Decenas = number => {
    const decena = Math.floor(number / 10)
    const unidad = number - decena * 10

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return 'diez'
          case 1:
            return 'once'
          case 2:
            return 'doce'
          case 3:
            return 'trece'
          case 4:
            return 'catorce'
          case 5:
            return 'quince'
          default:
            return `dieci${Unidades(unidad)}`
        }
      case 2:
        switch (unidad) {
          case 0:
            return 'veinte'
          default:
            return `veinti${Unidades(unidad)}`
        }
      case 3:
        return DecenasY('treinta', unidad)
      case 4:
        return DecenasY('cuarenta', unidad)
      case 5:
        return DecenasY('cincuenta', unidad)
      case 6:
        return DecenasY('sesenta', unidad)
      case 7:
        return DecenasY('setenta', unidad)
      case 8:
        return DecenasY('ochenta', unidad)
      case 9:
        return DecenasY('noventa', unidad)
      default:
        return Unidades(unidad)
    }
  }

  const DecenasY = (decenas, unidades) =>
    unidades > 0 ? `${decenas} Y ${Unidades(unidades)}` : decenas

  const Centenas = number => {
    const centenas = Math.floor(number / 100)
    const decenas = number - centenas * 100

    switch (centenas) {
      case 1:
        return decenas > 0 ? `ciento ${Decenas(decenas)}` : 'cien'
      case 2:
        return `doscientos ${Decenas(decenas)}`
      case 3:
        return `trescientos ${Decenas(decenas)}`
      case 4:
        return `cuatrocientos ${Decenas(decenas)}`
      case 5:
        return `quinientos ${Decenas(decenas)}`
      case 6:
        return `seiscientos ${Decenas(decenas)}`
      case 7:
        return `setecientos ${Decenas(decenas)}`
      case 8:
        return `ochocientos ${Decenas(decenas)}`
      case 9:
        return `novecientos ${Decenas(decenas)}`
      default:
        return Decenas(decenas)
    }
  }

  const Seccion = (number, divisor, letrasSingular, letrasPlural) => {
    const cientos = Math.floor(number / divisor)

    return cientos === 0
      ? ''
      : `${cientos > 1 ? Centenas(cientos) + ' ' + letrasPlural : letrasSingular}`
  }

  const Miles = number => {
    const cientos = Math.floor(number / 1_000)
    const resto = number - cientos * 1_000

    let letrasMiles = Seccion(number, 1_000, 'un mil', 'mil')
    const letrasCentenas = Centenas(resto)

    return `${letrasMiles} ${letrasCentenas}`.trim()
  }

  const Millones = number => {
    const cientos = Math.floor(number / 1_000_000)
    const resto = number - cientos * 1_000_000

    let letrasMillones = Seccion(number, 1_000_000, 'un millón', 'millones')
    if (resto === 0) letrasMillones += ' de'
    const letrasMiles = Miles(resto)

    return `${letrasMillones} ${letrasMiles}`.trim()
  }

  const singularCurrency = 'quetzal'
  const pluralCurrency = 'quetzales'

  const enteros = Math.floor(number)
  const centavos = Math.round(number * 100) - Math.floor(number) * 100

  const letrasEnteros = enteros === 0 ? 'cero' : Millones(enteros)
  const letrasCentavos =
    centavos > 0
      ? ` con ${Millones(centavos).trim()} ${centavos === 1 ? 'centavo' : 'centavos'}`
      : ''

  return `${letrasEnteros} ${
    enteros === 1 ? singularCurrency : pluralCurrency
  }${letrasCentavos}`.trim()
}
