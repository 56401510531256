import { $http, $httpPublic } from './http'

const BASE_PATH = '/card/provider'
const BASE_PATH_ORDER = '/order/card'

class CardProviderService {
  getProviders = async () => {
    const result = await $http.get(BASE_PATH)
    return result.data
  }

  onCreateConnection = async (request: ICardProviderConnection) => {
    const result = await $http.post(BASE_PATH, request)
    return result.data
  }

  onDeleteConnection = async (providerType: number) => {
    const result = await $http.delete(BASE_PATH + '/' + providerType)
    return result.data
  }

  getCardProvider = async (providerType: number) => {
    const result = await $http.get(BASE_PATH + '/' + providerType)
    return result.data
  }

  onCreateLinkPayment = async (orderId: number) => {
    const result = await $http.post(BASE_PATH_ORDER + '/' + orderId)
    return result.data
  }

  getLinkPayment = async (order: string, uuid: string) => {
    const result = await $httpPublic.get(`${BASE_PATH_ORDER}/public/${order}/${uuid}`)
    return result.data
  }

  getVoucherByDocumentId = async (documentId: number) => {
    const result = await $http.get(`${BASE_PATH_ORDER}/${documentId}/voucher`)
    return result.data
  }
}

export default new CardProviderService()
