import { $http } from './http'
import { saveAs } from 'file-saver'

class ResolutionsService {
  getDocumentsTypes = async () => {
    const response = await $http.get('/document/types')
    return response.data
  }

  getResolutions = async () => {
    const response = await $http.get('/resolutions')
    return response.data
  }

  getResolutionsLimit = async (docType, series) => {
    const response = await $http.get(`/resolutions/limit/${docType}?series=${series}`)
    return response.data
  }

  onCreateResolution = async (id, request) => {
    let response
    if (id) response = await $http.put(`resolutions/${id}`, request)
    else response = await $http.post('/resolutions', request)
    return response.data
  }

  getTransactions = async () => {
    const response = await $http.get('/transactions')
    return response.data
  }

  getInvoices = async () => {
    const response = await $http.get('/invoices')
    return response.data
  }

  cancelInvoice = async id => {
    const response = await $http.delete(`/invoices/${id}`)
    return response.data
  }

  getFreeResolutions = async () => {
    const response = await $http.get(`/resolutions/free`)
    return response.data
  }

  getCompanyDocumentsRequests = async () => {
    const response = await $http.get(`/resolutions/request/documents/`)
    return response.data
  }

  getDocumentRequest = async id => {
    const response = await $http.get(`/resolutions/request/documents/${id}`)
    return response.data
  }

  cancelDocumentRequest = async id => {
    const response = await $http.delete(`/resolutions/request/documents/${id}`)
    return response.data
  }

  getAllDocumentsRequests = async () => {
    const response = await $http.get(`/resolutions/request/documents/all`)
    return response.data
  }

  createDocumentRequest = async (
    contactName,
    contactPhone,
    contactEmail,
    signInName,
    signInPhone,
    signInJob,
  ) => {
    const response = await $http.post(`/resolutions/request/documents`, {
      contactName,
      contactPhone,
      contactEmail,
      signInName,
      signInPhone,
      signInJob,
    })
    return response.data
  }

  markDocumentRequestAsDone = async id => {
    const response = await $http.get(`/resolutions/request/documents/done/${id}`)
    return response.data
  }

  downloadDocuments = async id => {
    const response = await $http.get(`/resolutions/request/documents/download/${id}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const filename = response.headers['content-disposition']
      .replace('attachment; filename="', '')
      .replace('"', '')
    saveAs(file, filename.replace('json', 'pdf'))
  }

  downloadPrintableInvoice = async (id, defaultName, module) => {
    let params = ''
    if (module) params = `?module=${module}`
    const response = await $http.get(`/invoices/print/${id}${params}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - Orden - ' +
      defaultName +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
    /*

        var today = new Date();
        const filename = response.headers["content-disposition"]?
            response.headers["content-disposition"].replace('attachment; filename="','').replace('"',''):
            today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + " - Orden - " + defaultName + ".pdf";


        //saveAs(response.data, filename.replace('json','pdf'));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();*/
  }

  downloadPrintableInvoiceByOrder = async (
    id,
    defaultName,
    module,
    documentType,
    isCreditNote,
    save = true,
  ) => {
    const response = await $http.get(`/invoices/print/order/${id}`, {
      responseType: 'blob',
      params: { documentType, module, isCreditNote },
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ` - ${isCreditNote ? 'Nota de Crédito' : 'Order'} - ` +
      defaultName +
      '.pdf'
    if (save) saveAs(file, filename.replace('json', 'pdf'))
    else return file
  }

  sendInvoiceEmailRequest = async (invoice, purchase) => {
    const response = await $http.post(`/invoices/send`, invoice, {
      params: { purchase },
    })
    return response.data
  }

  cancelInvoiceAndOrder = async (
    invoice,
    order,
    returnProduct,
    account,
    balance,
    voucher,
    creditNote,
    request,
  ) => {
    let params = `product=${returnProduct}&`
    if (account) params += `account=${account}&`
    if (balance) params += `balance=${balance}&`
    if (voucher) params += `voucher=${voucher}&`
    if (creditNote) params += `creditNote=${creditNote}`
    const response = await $http.put(`invoices/${invoice}/${order}?${params}`, request)
    return response.data
  }

  getAdminFELInfo = async invoice => {
    const response = await $http.post(`/invoices/admin/fel`, invoice)
    return response.data
  }

  toogleAdminFELInfo = async id => {
    const response = await $http.post(`/invoices/admin/fel/${id}`)
    return response.data
  }

  getFELData = async () => {
    const response = await $http.get(`/invoices/fel`)
    return response.data
  }

  createFELData = async request => {
    const response = await $http.post(`/invoices/fel`, {
      ...request,
      active: false,
    })
    return response.data
  }

  getInvoiceProfileOPtions = async () => {
    const response = await $http.get('/invoices/profile/options')
    return response.data
  }

  getInvoiceProfile = async () => {
    const response = await $http.get('/invoices/profile')
    return response.data
  }

  onUpdateInvoiceProfile = async (profileId, sellOwnProduct) => {
    const response = await $http.put(`/invoices/profile/${profileId}/${sellOwnProduct}`)
    return response.data
  }

  getSingleInvoice = async id => {
    const response = await $http.get(`/invoices/${id}`)
    return response.data
  }

  getPhaseTypes = async type => {
    const response = await $http.get(`/fel`, { params: { type } })
    return response.data
  }

  getCompanyPhrases = async () => {
    const response = await $http.get(`/fel/phrases`)
    return response.data
  }

  setCompanyPhrases = async phrases => {
    await $http.post(`/fel/phrases`, phrases)
  }

  exportDTE = async params => {
    const response = await $http.get('/invoices/export/dte', {
      params,
      responseType: 'blob',
    })

    const fileName = 'Libro de Ventas.xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  exportDTEProducts = async params => {
    const response = await $http.get('/invoices/export/dte/products', {
      params,
      responseType: 'blob',
    })

    const fileName = 'reporte Ítems DTE.xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  downloadPrintableInvoiceByPurchase = async (id, defaultName, module) => {
    let params = ''
    if (module) params = `?module=${module}`
    const response = await $http.get(`/invoices/print/purchase/${id}${params}`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], { type: 'application/pdf' })
    const today = new Date()
    const filename =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' - Compra - ' +
      defaultName +
      '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  /**
   * Services to nullify order and invoice
   * @param {number} orderId Order id to nullify
   * @param {Object} params Params to send to the API
   * @param {boolean} params.returnProduct If the product should be returned
   * @returns
   */
  nullifyInvoiceAndOrder = async (orderId, params) => {
    const response = await $http.post(`invoices/order/nullify/${orderId}`, null, {
      params,
    })
    return response.data
  }

  deleteResolution = async resolutionId => {
    const response = await $http.delete(`/resolutions/${resolutionId}`)
    return response.data
  }
}

export default new ResolutionsService()
