import { actionTypes } from 'src/actions/orders.actions'
import { actionTypes as companyActionTypes } from 'src/actions/company.actions'
import { extract, successState } from 'src/actions/global.actions'

const initialState = {
  order: {},
  orders: [],
  summary: {},
  dashboardOrders: [],
  ordersMap: [],
  totalOrders: 0,
  DTotalOrders: 0,
  ordersProduct: [],
  orderStatus: [],
  orderTypes: [],
  ordersWithBalance: { orders: [], totalBalance: 0 },
  orderVisit: null,
  orderPayment: null,
  paymentConfirm: null,
  visitConfirm: null,
  presaleConfirm: null,
  rate: [],
  visitOrders: [],
  orderPayments: [],
  ordersToPay: [],
  externalOrders: [],
  ordersPreSale: [],
  ordersByClient: [],
  orderByClient: {},
  gOrders: [],
  usersOrders: [],
  adminOrders: [],
  delivererReport: [],
  sellerReport: [],
  productReport: [],
  sumOrders: {},
  updated: {},
  group: [],
  packages: [],
  history: [],
  existence: [],
  singlePackage: {},
  linked: [],
  status: null,
  transfers: [],
  paymentOrders: [],
  totalPaymentOrders: 0,
  report: [],
  balance: {},
  invoice: [],
  usersReport: [],
  route: [],
  counts: {},
  admin: [],
  visitResponse: null,
  historyPayment: [],
  cardsLiquidation: [],
  ordersLiquidated: [],
  productsProcessAvailable: [],
  paymentRepeat: false,
  archived: [],
  orderMatrix: {},
  paymentSee: { id: null },
  paymentsByOrder: [],
  ordersLite: [],
  paymentsOrder: [],
  responseMassiveConfirmation: {},
  printInvoice: {
    active: false,
    orderId: null,
  },
  ordersAddons: [],
  versions: [],
  versionItems: [],
  orderUseRecipes: false,
  orderIdToDetail: null,
  orderDetails: {},
  cardPaymentAmounts: {
    accredited: 0,
  },
}

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.CLEAR_SINGLE_ORDER):
      return { ...state, order: {} }
    case successState(actionTypes.GET_ORDER_DETAILS):
      return { ...state, orderDetails: extract(action.payload, {}) }
    case successState(actionTypes.ORDER_DETAIL_ID):
      return { ...state, orderIdToDetail: extract(action.id, null) }
    case successState(actionTypes.VERIFY_ORDER_USE_RECIPES):
      return { ...state, orderUseRecipes: extract(action.response, false) }
    case successState(companyActionTypes.GET_COMPANY_DATA): {
      return {
        ...state,
        orderStatus: extract(action.payload.orderStatuses, []),
      }
    }
    case successState(companyActionTypes.GET_COMPANY_DASHBOARD): {
      return {
        ...state,
        dashboardOrders: action.payload.orders ? action.payload.orders.orders : [],
        DTotalOrders: action.payload.orders ? action.payload.orders.totalOrders : 0,
        balance: extract(action.payload.ordersBalance, []),
      }
    }
    case successState(actionTypes.GET_ALL_ORDERS_LITE):
      return { ...state, ordersLite: extract(action.ordersLite, []) }
    case successState(actionTypes.GET_PAYMENTS_BY_ORDER):
      return { ...state, paymentsByOrder: extract(action.response, []) }
    case successState(actionTypes.GET_ORDERS_TO_PAY_BY_ORDER):
      return {
        ...state,
        ordersToPay: extract(action.payload, []).filter(o => Boolean(o)),
      }
    case successState(actionTypes.SEE_PAYMENT_TYPE):
      return { ...state, paymentSee: extract(action.paymentSee, { id: null }) }
    case successState(actionTypes.GET_QUOTE_ADJUSTMENT):
      return { ...state, orderMatrix: extract(action.response, {}) }
    case successState(actionTypes.PRODUCTS_PROCESS_AVAILABLE):
      return {
        ...state,
        productsProcessAvailable: extract(action.productsProcessAvailable, []),
      }
    case successState(actionTypes.GET_ORDERS_LIQUIDATED):
      return {
        ...state,
        ordersLiquidated: extract(action.ordersLiquidated, []),
      }
    case successState(actionTypes.GET_CARD_LIQUIDATION):
      return {
        ...state,
        cardsLiquidation: extract(action.cardsLiquidation, []),
      }
    case successState(actionTypes.GET_HISTORY):
      return { ...state, historyPayment: extract(action.historyPayment, []) }
    case successState(actionTypes.GET_ROUTE):
      return { ...state, route: extract(action.route, []) }
    case successState(actionTypes.CONFIRM_SINGLE_ORDER):
      return { ...state, invoice: extract(action.order, []) }
    case successState(actionTypes.GET_PAYMENT_ORDERS):
      return {
        ...state,
        paymentOrders: action.paymentOrders ? action.paymentOrders.orders : [],
        totalPaymentOrders: action.paymentOrders ? action.paymentOrders.totalOrders : 0,
      }
    case successState(actionTypes.GET_ORDERS_LINKED): {
      return {
        ...state,
        orders: action.orders ? action.orders.orders : [],
        totalOrders: action.orders ? action.orders.totalOrders : 0,
      }
    }
    case successState(actionTypes.UPDATE_PRODUCTS_ORDER): {
      return {
        ...state,
        updated: extract(action.updated, {}),
      }
    }
    case successState(actionTypes.GET_G_ORDERS): {
      return {
        ...state,
        gOrders: extract(action.orders, []),
      }
    }
    case successState(actionTypes.ORDER_BY_CLIENT): {
      return {
        ...state,
        orderByClient: extract(action.orderByClient, {}),
      }
    }
    case successState(actionTypes.ORDERS_BY_CLIENT): {
      return {
        ...state,
        ordersByClient: extract(action.ordersByClient, []),
      }
    }
    case successState(actionTypes.GET_ORDERS_PRE_SALE): {
      return {
        ...state,
        ordersPreSale: extract(action.ordersPreSale, []),
      }
    }
    case successState(actionTypes.GET_ALL_ORDERS_PRODUCT): {
      return {
        ...state,
        ordersProduct: extract(action.ordersProduct, []),
      }
    }
    case successState(actionTypes.GET_PAYMENTS_BY_CPP):
      return { ...state, paymentsOrder: extract(action.documents, []) }
    case actionTypes.GET_EXTERNAL_ORDERS_SUCCESS:
      return {
        ...state,
        externalOrders: action.response,
      }
    case actionTypes.GET_PRODUCT_PRICES_SUCCESS:
      return {
        ...state,
        orders: action.orders,
      }
    case actionTypes.GET_ALL_ORDER_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        orderPayments: action.orderPayments,
      }
    case actionTypes.GET_VISITS_ORDERS_SUCCESS:
      return {
        ...state,
        visitOrders: action.orders,
      }
    case successState(actionTypes.ON_CONFIRM_VISIT_ORDER): {
      return {
        ...state,
        order: extract({ id: action.response }, {}),
        //order: action.response && action.response.length > 0 ? {...action.response[0].order, client: action.response[0].client} : {}
      }
    }
    case actionTypes.ON_CONFIRM_PAYMENT_ORDER_SUCCESS: {
      return {
        ...state,
        paymentConfirm: action.response,
      }
    }
    case actionTypes.CONVERT_PRESALE_SUCCESS: {
      return {
        ...state,
        presaleConfirm: action.response,
      }
    }
    case successState(actionTypes.ON_CREATE_PAYMENT_ORDER): {
      return {
        ...state,
        orderPayment: extract(action.orderPayment, null),
      }
    }
    case successState(actionTypes.ON_CREATE_VISIT_ORDER): {
      return {
        ...state,
        orderVisit: extract(action.orderVisit, null),
      }
    }
    case successState(actionTypes.CREATE_NEW_ORDER): {
      return { ...state, order: extract(action.order, {}) }
    }
    case actionTypes.GET_ALL_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: action.orders ? action.orders.orders : [],
        totalOrders: action.orders ? action.orders.totalOrders : 0,
      }
    }
    case actionTypes.GET_ALL_ORDERS_MAP_SUCCESS: {
      return {
        ...state,
        ordersMap: action ? action.orders : [],
      }
    }
    case actionTypes.GET_ALL_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        orderStatus: action.orderStatus || [],
      }
    }
    case actionTypes.GET_ALL_ORDER_TYPES_SUCCESS: {
      return {
        ...state,
        orderTypes: action.orderTypes,
      }
    }
    case successState(actionTypes.GET_SINGLE_ORDER): {
      return {
        ...state,
        order: extract(action.order, {}),
      }
    }
    case actionTypes.DELETE_SINGLE_ORDER_SUCCESS: {
      return {
        ...state,
        status: action.status,
      }
    }
    case actionTypes.CONFIRM_SINGLE_ORDER_SUCCESS: {
      return {
        ...state,
        invoice: action.order,
      }
    }
    case actionTypes.GET_TRANSPORTIST_RATE_SUCCESS:
      return {
        ...state,
        rate: action.response,
      }
    case actionTypes.GET_SINGLE_CLIENT_HISTORY_SUCCESS: {
      return {
        ...state,
        orders: action.orders,
      }
    }
    case successState(actionTypes.GET_ORDER_BALANCE):
      return {
        ...state,
        ordersWithBalance: extract(action.ordersWithBalance, []),
      }
    case successState(actionTypes.GET_INVOICE):
      return {
        ...state,
        invoice: extract(action.invoice, []),
      }
    case successState(actionTypes.GET_SALE_ORDERS_BALANCE_CURRENT_USER):
      return {
        ...state,
        saleOrderBalanceCurrentUser: extract(action.response, 0),
      }
    case successState(actionTypes.GET_BI_PAYMENTS_HISTORY_CURRENT_USER):
      return {
        ...state,
        biPaymentsHistoryCurrentUser: extract(action.response, []),
      }
    case successState(actionTypes.ORDER_USERS):
      return {
        ...state,
        usersOrders: extract(action.orderUsers, []),
      }
    case successState(actionTypes.CONFIRM_VISIT_ORDER_NO_SALE):
      return {
        ...state,
        usersOrders: extract(action.orderUsers, []),
      }
    case successState(actionTypes.All_ADMIN_ORDERS):
      return {
        ...state,
        orders: extract(action.ordersAdmin, []),
      }
    case successState(actionTypes.GET_USERS_REPORT):
      return {
        ...state,
        usersReport: extract(action.usersReport, []),
      }
    case successState(actionTypes.GET_CARD_PAYMENT_AMOUNTS):
      return {
        ...state,
        cardPaymentAmounts: action.response,
      }
    case successState(actionTypes.GET_DELIVERER_REPORT):
      return {
        ...state,
        delivererReport: extract(action.deliverReport, []),
      }
    case successState(actionTypes.GET_SELLER_REPORT):
      return {
        ...state,
        sellerReport: extract(action.sellerReport, []),
      }
    case successState(actionTypes.GET_PRODUCT_REPORT):
      return {
        ...state,
        productReport: extract(action.productReport, []),
      }
    case successState(actionTypes.GET_BALANCE_REPORT):
      return {
        ...state,
        balance: extract(action.balance, []),
      }
    case successState(actionTypes.GET_GROUP): {
      return {
        ...state,
        group: extract(action.orders, []),
      }
    }
    case successState(actionTypes.GET_GROUP_BY_PACKAGES): {
      return {
        ...state,
        packages: extract(action.packages, []),
      }
    }
    case successState(actionTypes.WAREHOUSE_HISTORY): {
      return {
        ...state,
        history: extract(action.history, []),
      }
    }
    case successState(actionTypes.GET_EXISTENCE): {
      return {
        ...state,
        existence: extract(action.existence, []),
      }
    }
    case successState(actionTypes.GET_PRODUCTS_ON_ORDERS): {
      return {
        ...state,
        productReport: extract(action.report, []),
      }
    }
    case successState(actionTypes.GET_PACKAGE): {
      return {
        ...state,
        singlePackage: action.singlePackage,
      }
    }
    case successState(actionTypes.GET_TRANSFER_ORDERS): {
      return {
        ...state,
        transfers: extract(action.orders, []),
      }
    }
    case successState(actionTypes.GET_ORDER_REPORT): {
      return {
        ...state,
        report: extract(action.report, []),
      }
    }
    case successState(actionTypes.GET_ORDER_COUNT): {
      return {
        ...state,
        counts: extract(action.counts, {}),
      }
    }
    case successState(actionTypes.GET_ADMIN_REPORT): {
      return {
        ...state,
        admin: extract(action.report, []),
      }
    }
    case successState(actionTypes.GET_PAYMENT_RECEIPT): {
      return {
        ...state,
        paymentRepeat: extract(action.paymentReceipt, {}),
      }
    }
    case successState(actionTypes.GET_ARCHIVED_PAYMENT): {
      return {
        ...state,
        archived: extract(action.archived, []),
      }
    }
    case successState(actionTypes.EXISTENCE_ORDER): {
      return { ...state, existence: extract(action.response, []) }
    }
    case successState(actionTypes.GET_ORDERS_SUMMARY): {
      return { ...state, summary: extract(action.response, {}) }
    }
    case successState(actionTypes.MASSIVE_CONFIRMATION): {
      return { ...state, responseMassiveConfirmation: extract(action.response, {}) }
    }
    case successState(actionTypes.PRINT_INVOICE): {
      return { ...state, printInvoice: extract(action.response, {}) }
    }
    case successState(actionTypes.GET_ORDERS_ADDONS): {
      return {
        ...state,
        ordersAddons: action.response ? action.response.orders : [],
      }
    }
    case successState(actionTypes.GET_ORDER_VERSION): {
      return {
        ...state,
        versions: extract(action.response, []),
      }
    }
    case successState(actionTypes.GET_ORDER_VERSION_ITEMS): {
      return {
        ...state,
        versionItems: extract(action.response, []),
      }
    }
    default:
      return state ? state : initialState
  }
}
export default OrderReducer
