import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button, Icon, TableV2 } from 'src/components'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DiscountPolicy } from 'src/content/Products/Policies/DiscountPolicy'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { PolicyTypeNames } from 'src/content/Products/Policies/PolicyType'

interface DiscountPoliciesProps {
  policies: IDiscountPolicies[]
  onChange: (policies: IDiscountPolicies[]) => void
}

const headers: ITableHeader[] = [
  {
    show: true,
    label: 'Monto',
    value: ['amount'],
    type: 'number',
  },
  {
    show: true,
    label: 'Porcentual %',
    value: ['percentage'],
    type: 'yesOrNo',
  },
  {
    show: true,
    label: 'Cantidad minima',
    value: ['min'],
    type: 'number',
  },
  {
    show: true,
    label: 'Cantidad maxima',
    value: ['max'],
    type: 'number',
  },
]

interface CreateOrUpdateProps {
  policy?: IDiscountPolicies
  show: boolean
}

export const DiscountPolicies = ({ policies, onChange }: DiscountPoliciesProps) => {
  const [createOrUpdate, setData] = useState<CreateOrUpdateProps>({ show: false })

  const heads: ITableHeader[] = [
    {
      show: true,
      label: 'Tipo',
      value: [],
      type: 'custom',
      custom: (policy: IDiscountPolicies) => PolicyTypeNames[policy.type],
    },
    ...headers,
    {
      show: true,
      label: 'Acciones',
      value: [],
      type: 'custom',
      custom: (policy: IDiscountPolicies) => (
        <Row className={'center'}>
          <Icon
            icon={faTrash}
            color={'secondary'}
            tooltip={'Eliminar'}
            className={'mr-2'}
            onClick={() => {
              onChange(policies.filter(p => p.key !== policy.key))
            }}
          />
          <Icon
            icon={faEdit}
            color={'accent'}
            tooltip={'Editar'}
            onClick={() => setData({ show: true, policy })}
          />
        </Row>
      ),
    },
  ]

  return (
    <>
      <Row className={'mt-3'}>
        <Col xl={12}>
          <Row className={'container-buttons'}>
            <Button icon={faPlus} onClick={() => setData({ show: true })}>
              Nueva política
            </Button>
          </Row>
        </Col>
        <Col xl={12}>
          <TableV2
            // @ts-ignore
            headers={heads}
            items={policies}
          />
        </Col>
      </Row>

      <DiscountPolicy
        show={createOrUpdate.show}
        policy={createOrUpdate.policy}
        onChange={policy => {
          setData({ show: false })
          if (haveAnyValue(policy.key)) {
            const index = policies.findIndex(p => p.key === policy.key)
            const copy = [...policies]
            copy[index] = policy
            onChange(copy)
          } else onChange([...policies, { ...policy, key: new Date().valueOf() }])
        }}
        onClose={() => setData({ show: false })}
      />
    </>
  )
}
