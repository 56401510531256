import React from 'react'
import { useSelector } from 'react-redux'

import { MultipleSelect } from 'src/components/custom/MutipleSelects/MultipleSelect'
import { selectOwnWarehouseLite } from 'src/selectors/warehouse.selector'

interface SelectUsersProps {
  label: string
  onChange: (users: ISelect[]) => void
}
export const SelectWarehouses = ({ label, onChange }: SelectUsersProps) => {
  const warehouses = useSelector(selectOwnWarehouseLite)

  return <MultipleSelect options={warehouses} label={label} onChange={onChange} />
}
