import React from 'react'
import { useSelector } from 'react-redux'

import { Icon, TableV2 } from 'src/components'

import { selectCardProviders } from 'src/selectors/cardProvider.selector'
import { CardProviderName } from 'src/enums/CardProviderEnum'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { isAllowed } from 'src/selectors/modules.selector'
import { cardProviderPermissions } from 'src/enums/permissions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { actions } from 'src/actions/cardProvider.actions'

interface CardProviderListProps {
  onSelect: (provider: ICardProvider, see: boolean) => void
  loadingDelete: boolean
}

export const CardProviderList = ({ onSelect, loadingDelete }: CardProviderListProps) => {
  const providers = useSelector(selectCardProviders)

  const loading = useSelector(state =>
    loadingSelector([actions.GET_CARD_PROVIDERS])(state),
  )

  const canDelete = useSelector(state =>
    isAllowed(state, [cardProviderPermissions.delete]),
  )

  const renderActions = (provider: ICardProvider) => {
    return (
      <div className={'center'}>
        <Icon
          icon={faEye}
          tooltip={'Ver detalle'}
          onClick={() => onSelect(provider, true)}
          className={'mr-2'}
          disabled={loadingDelete}
        />
        {canDelete && (
          <Icon
            icon={faTrash}
            tooltip={'Eliminar'}
            onClick={() => onSelect(provider, false)}
            className={'mr-2'}
            loading={loadingDelete}
          />
        )}
      </div>
    )
  }

  const headers: ITableHeader[] = [
    {
      show: true,
      label: 'Proveedor',
      type: 'custom',
      value: [],
      custom: provider => CardProviderName[provider.id],
    },
    {
      show: true,
      label: 'Configurado por',
      type: 'text',
      value: ['createdBy'],
    },
    {
      show: true,
      label: 'Fecha de configuración',
      type: 'date',
      value: ['createdAt'],
    },
    {
      show: true,
      label: 'Acciones',
      type: 'custom',
      className: 'mini',
      value: [],
      custom: provider => renderActions(provider),
    },
  ]

  return (
    <TableV2
      // @ts-ignore
      loading={loading}
      items={providers}
      headers={headers}
    />
  )
}
