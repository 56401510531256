import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { Modal, Row } from 'react-bootstrap'

import {
  actions,
  getCardVoucher,
  setCardPaymentStatusRequest,
} from 'src/actions/cardProvider.actions'
import {
  selectCardVoucherResponse,
  selectSeeCardPaymentStatus,
} from 'src/selectors/cardProvider.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { getPaymentData } from 'src/content/CardProviders/CardPaymentFunctions'
import { CardVoucher } from 'src/content/CardProviders/CardVoucher'
import { Loading } from 'src/components'

export const CardPaymentResponse = () => {
  const dispatch = useDispatch()
  const request = useSelector(selectSeeCardPaymentStatus)

  const show = haveAnyValue(request.documentId)

  const response = useSelector(selectCardVoucherResponse)
  const loading = useSelector(state =>
    loadingSelector([actions.GET_CARD_PAYMENT_VOUCHER])(state),
  )
  const error = useSelector(state => hasErrors([actions.GET_CARD_PAYMENT_VOUCHER])(state))

  const [flag, setFlag] = useState(false)
  const [data, setData] = useState<ISelectString[]>([])

  useEffect(() => {
    if (!show) return
    dispatch(getCardVoucher(request.documentId))
  }, [show])

  useEffect(() => {
    if (loading) setFlag(true)
    else if (flag) {
      setFlag(false)
      if (error)
        dispatch(
          showAlert({ ...handlerError(error.message()), onConfirm: () => onClose() }),
        )
      else setData(getPaymentData(response))
    }
  }, [loading])

  const onClose = () => {
    dispatch(setCardPaymentStatusRequest({}))
  }

  return (
    <Modal show={show} centered onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Detalle del pago: {request.documentCode}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loading show /> : <CardVoucher date={response?.date} data={data} />}
      </Modal.Body>
    </Modal>
  )
}
