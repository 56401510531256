export const selectCardProviders = (state: IGlobalState): ICardProvider[] =>
  state.cardProvider.providers
export const selectCardProvider = (state: IGlobalState): ICardProviderCredentialsDTO =>
  state.cardProvider.provider
export const selectCardLinkPayment = (state: IGlobalState): ICardLinkPaymentDTO =>
  state.cardProvider.cardLinkResponse
export const selectCardVoucherResponse = (state: IGlobalState): ICardVoucherDTO =>
  state.cardProvider.cardVoucherResponse
export const selectCardLinkRequest = (state: IGlobalState): ICardLinkRequest =>
  state.cardProvider.linkRequest
export const selectSeeCardPaymentStatus = (state: IGlobalState): ICardLinkRequest =>
  state.cardProvider.seeCardPaymentStatus
