import React, { useEffect, useState } from 'react'
import FoldersComponent from './FoldersComponent'
import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import FormTextField from 'src/components/inputs/FormTextField/FormTextField'
import Button from 'src/components/buttons/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  createCategorization,
  deleteCategorization,
  getAllCategorizations,
  getAllRootCategorizations,
  updateCategorization,
} from 'src/actions/categorization.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { Select } from '../index'
import { isAllowed } from 'src/selectors/modules.selector'
import Alert from 'sweetalert-react'
import {
  errorsSelector,
  handlerError,
  handlerSuccess,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

export const categoryType = {
  PRODUCTS: {
    id: 3,
    options: [
      { value: 2, label: 'Gastos' },
      { value: 3, label: 'Ítem' },
      { value: 12, label: 'Puntos de venta' },
    ],
  },
  EXPENSES: { id: 2, options: [{ value: 2, label: 'Gastos' }] },
  KOLO: { id: 4, options: [{ value: 4, label: 'KOLO' }] },
  CLIENTS: { id: 5, options: [{ value: 5, label: 'Clientes' }] },
  PURCHASE: { id: 6, options: [{ value: 6, label: 'Compras' }] },
  VIDEO: { id: 11, options: [{ value: 11, label: 'Video' }] },
  RESTAURANT: { id: 12, options: [{ value: 12, label: 'Puntos de venta' }] },
  DATASHEET: { id: 15, options: [{ value: 15, label: 'Datasheet' }] },
  GENERAL: { id: 17, options: [{ value: 17, label: 'General' }] },
  CPP: {
    id: 18,
    options: [
      { value: 2, label: 'Gastos' },
      { value: 6, label: 'Compras' },
    ],
  },
  MANUALES: { id: 19, options: [{ value: 19, label: 'Manuales' }] },
  POS_TERMINAL: {
    id: 22,
    options: [{ value: 22, label: 'Terminales de punto de ventas' }],
  },
}

export const flatCategories = categories =>
  categories.children?.map(category => [category, ...flatCategories(category)]).flat()

const Folder = ({
  data1,
  data2,
  onHide,
  onAssign,
  show,
  loading = false,
  noMessage,
  onDelete,
  type,
  see,
  list,
  companyId,
}) => {
  const dispatch = useDispatch()

  const canAdd = useSelector(state => type && isAllowed(state, [6002, 6102]))
  const canEdit = useSelector(state => (see || type) && isAllowed(state, [6003, 6103]))
  const canRemove = useSelector(state => (see || type) && isAllowed(state, [6004, 6104]))

  const loadingAction = useSelector(state =>
    loadingSelector([
      actionTypes.CREATE_CATEGORIZATION,
      actionTypes.UPDATE_CATEGORIZATION,
    ])(state),
  )
  const hasErrorAction = useSelector(state =>
    hasErrorsSelector([
      actionTypes.CREATE_CATEGORIZATION,
      actionTypes.UPDATE_CATEGORIZATION,
      actionTypes.DELETE_CATEGORIZATION,
    ])(state),
  )
  const errorAction = useSelector(state =>
    errorsSelector([
      actionTypes.CREATE_CATEGORIZATION,
      actionTypes.UPDATE_CATEGORIZATION,
      actionTypes.DELETE_CATEGORIZATION,
    ])(state),
  )
  const loadingDelete = useSelector(state =>
    loadingSelector([actionTypes.DELETE_CATEGORIZATION])(state),
  )

  const [selected, setSelected] = useState({})
  const [action, setAction] = useState({ action: false })
  const [alert, setAlert] = useState({ title: 'default', show: false })
  const [removeL, setRemove] = useState({ id: 0, loading: false })

  useEffect(() => {
    if (type) setSelected({ ...selected, type: type.id })
  }, [])

  useEffect(() => {
    if (loadingAction || loadingDelete) setAction({ ...action, action: true })
    else if (action.action) {
      setAction({ action: false })
      if (hasErrorAction) {
        setAlert({
          ...handlerError(errorAction.message || 'Error inesperado'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
      } else {
        setAlert({
          ...handlerSuccess('Acción realizada'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            if (see) dispatch(getAllRootCategorizations())
            else dispatch(getAllCategorizations(type ? type.id : null, true))
          },
        })
      }
      setSelected({ show: false, type: type ? type.id : null })
      setRemove({ id: 0, loading: false })
    }
  }, [loadingAction, loadingDelete])

  const folderElement = (
    <div>
      {loading && (
        <Row>
          <Col>
            <div className={'pb-custom'}>
              <ProgressBar
                label="Cargando"
                animated
                now={100}
                style={{ marginBottom: 10 }}
              />
            </div>
          </Col>
        </Row>
      )}
      {data1 && (
        <FoldersComponent
          list={list}
          see={see}
          categorization={data1}
          onAssign={onAssign}
          noMessage={noMessage}
        />
      )}
      {data2 && (
        <FoldersComponent
          list={list}
          edit={canEdit || companyId}
          see={see}
          noMessage={noMessage}
          remove={canRemove || companyId}
          add={canAdd || companyId}
          type={type}
          categorization={data2}
          onAssign={onAssign}
          loadingAction={removeL}
          onEdit={item => {
            setSelected({
              ...item,
              show: true,
              type: type
                ? type.options.find(d => d.value === item.type)
                : { value: item.type },
              publicCategorization: !!item.publicCategorization,
            })
          }}
          onDelete={node => {
            if (onDelete) onDelete(node.id)
            else
              setAlert({
                show: true,
                title: '¡Advertencia!',
                text: `¿ Desea eliminar la categoria ${node.name}? Esta acción no podra ser revertida.`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar',
                type: 'warning',
                onCancel: () => setAlert({ ...alert, show: false }),
                onConfirm: () => {
                  dispatch(deleteCategorization(node.id))
                  setAlert({ ...alert, show: false })
                  setRemove({ id: node.id, loading: true })
                },
              })
          }}
          onAdd={node => {
            if (canAdd || companyId)
              setSelected({
                show: true,
                parentCategorizationId: node.id,
                type: type
                  ? type.options.find(d => d.value === node.type)
                  : { value: node.type },
                publicCategorization: !!node.publicCategorization,
              })
          }}
        />
      )}

      <Modal
        show={selected.show}
        size={'md'}
        centered
        onHide={() => setSelected({ show: false, type: type ? type.id : null })}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Categorización</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'name'}
                label={'Nombre'}
                placeholder={'Nombre'}
                type={'text'}
                required
                value={selected.name}
                onChange={({ target }) =>
                  setSelected({ ...selected, name: target.value })
                }
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormTextField
                id={'description'}
                label={'Descripción'}
                placeholder={'Descripción'}
                type={'text'}
                required
                value={selected.description}
                onChange={({ target }) =>
                  setSelected({ ...selected, description: target.value })
                }
              />
            </Col>
            {type && (
              <Col xs={12} sm={12} md={12} lg={12}>
                <Select
                  disabled={selected.parentCategorizationId || selected.id}
                  label={'Tipo'}
                  options={type.options}
                  required
                  value={selected.type}
                  onChange={type => setSelected({ ...selected, type })}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={loadingAction}
            disabled={!selected.name || !selected.description || !selected.type}
            onClick={() => {
              if (selected.id)
                dispatch(
                  updateCategorization(
                    selected.id,
                    { ...selected, type: selected.type.value },
                    companyId,
                  ),
                )
              else
                dispatch(
                  createCategorization(
                    { ...selected, type: selected.type.value },
                    null,
                    null,
                    null,
                    companyId,
                  ),
                )
            }}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )

  return onHide ? (
    <Modal
      show={show}
      size={'lg'}
      centered
      aria-labelledby={'contained-modal-title-vcenter'}
      onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Asignar Categorías</Modal.Title>
      </Modal.Header>
      <Modal.Body>{folderElement}</Modal.Body>
    </Modal>
  ) : (
    folderElement
  )
}

export default Folder
