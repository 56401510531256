import React from 'react'

import { Icon, Paragraph } from 'src/components'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { formatDateFromMillis } from 'src/utils/formatters'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { posTypesEnum } from 'src/enums/posTypes'
import { ElapsedTime } from 'src/components/Time/ElapsedTime'

interface Props {
  posTerminalImg: any
  terminal: IPOSTerminal
  col: string
  posType: number
  seeImg: boolean
  seeUser: boolean
  index: number
  editTerminal: boolean
  onClick: () => void
  onEdit: () => void
}
const POSTerminalContent = ({
  terminal,
  col,
  posType,
  seeImg,
  seeUser,
  onClick,
  index,
  posTerminalImg,
  editTerminal,
  onEdit,
}: Props) => {
  const { number, user, free, date } = terminal
  const { description, imgUrl, tags } = terminal

  const name =
    posType === posTypesEnum.PARKING.type
      ? number
      : number && number.length >= 5
      ? number
      : 'Terminal: ' + number

  const mainClass = col
    .concat(` b-item pos-terminal ${!seeImg ? '' : 'no-padding'} `)
    .concat(free ? 'border-free' : 'border-occupied')

  return (
    <div className={mainClass}>
      <div
        className={'d-flex'}
        data-cy={'pos-terminal-' + index}
        onClick={() => onClick()}>
        {seeImg && (
          <div
            className={
              posType !== posTypesEnum.PARKING.type
                ? free
                  ? 'background-free'
                  : 'background-occupied'
                : ''
            }>
            <img
              className={'pos-terminal-img'}
              src={imgUrl || posTerminalImg}
              alt="terminal"
            />
          </div>
        )}

        <div className={'column left pl-3'} style={{ width: '100%' }}>
          <Paragraph>{name}</Paragraph>
          {haveAnyValue(description) && <Paragraph dim>{description}</Paragraph>}
          {haveAnyValue(tags) && (
            <Paragraph italic dim>
              {tags}
            </Paragraph>
          )}
          {seeUser && <Paragraph>{free ? 'LIBRE' : user ? user.name : ''}</Paragraph>}
          {!free && (
            <div className={'column'}>
              <Paragraph>Ingreso: {formatDateFromMillis(date)}</Paragraph>
              <ElapsedTime start={date} />
            </div>
          )}
        </div>
      </div>

      {editTerminal && (
        <div className={'pos-terminal-icon'}>
          <Icon
            icon={faEdit}
            color={'black'}
            tooltip={'Editar'}
            onClick={() => onEdit()}
          />
        </div>
      )}
    </div>
  )
}
export default POSTerminalContent
