import { faBan } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'

import { Modal, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPaymentsByOrder,
  onNullifyPayment,
  actionTypes as orderActionTypes,
} from 'src/actions/orders.actions'
import { setModalOrderPaymentsInfo } from 'src/actions/utilities.actions'
import { TableV2, Card, Icon } from 'src/components'
import { hasErrorsSelector } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import { selectPaymentsByOrder } from 'src/selectors/orders.selector'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { selectModalOrderPayments } from 'src/selectors/utilities.selector'

const ModalOrderPayments = () => {
  const dispatch = useDispatch()

  const [selected, setSelected] = useState({})

  const orderInfo = useSelector(selectModalOrderPayments)
  const { show, order } = orderInfo
  const paymentOrders = useSelector(selectPaymentsByOrder)
  const company = useSelector(selectCurrentCompany)

  const balancePermission = useSelector(state => isAllowed(state, [1067, 2167]))

  const loadingGetPayments = useSelector(state =>
    loadingSelector([orderActionTypes.GET_PAYMENTS_BY_ORDER])(state),
  )

  const loadingNullifyPayment = useSelector(state =>
    loadingSelector([orderActionTypes.ON_NULLIFY_PAYMENT])(state),
  )
  const hasErrorNullifyPayment = useSelector(state =>
    hasErrorsSelector([orderActionTypes.ON_NULLIFY_PAYMENT])(state),
  )

  const headers = [
    { label: 'Código', show: true, type: 'text', value: ['number'] },
    {
      label: 'Tipo',
      show: true,
      type: 'text',
      value: [],
      custom: ({ type }) => (type === 4 ? 'Vale / Nota de crédito' : 'Pago / recibo'),
    },
    {
      label: 'Estado',
      show: true,
      type: 'text',
      value: ['statusData', 'name'],
    },
    { label: 'Fecha', show: true, type: 'date', value: ['date'] },
    { label: 'Total', show: true, type: 'currency', value: ['total'] },
    {
      config: true,
      show: true,
      label: '',
      className: 'mini',
      custom: item =>
        item.type !== 4 &&
        item.status === 2 && (
          <Icon
            icon={faBan}
            tooltip={'Anular pago'}
            disabled={loadingNullifyPayment}
            onClick={() => {
              setSelected(item)
              dispatch(onNullifyPayment(item.documentId))
            }}
          />
        ),
    },
  ]

  useEffect(() => {
    if (!show) return
    dispatch(getPaymentsByOrder(order.id))
  }, [show])

  useEffect(() => {
    if (!show || !selected.id || loadingNullifyPayment) return
    setSelected({})
    dispatch(getPaymentsByOrder(order.id))
  }, [loadingNullifyPayment, hasErrorNullifyPayment])

  const onHide = () => {
    dispatch(setModalOrderPaymentsInfo({ order: {}, show: false }))
  }

  return (
    <>
      <Modal show={show} size={'xl'} centered onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Abonos: {order.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Card>
                <TableV2
                  customClass={'scroll-x-without-height'}
                  loading={loadingGetPayments}
                  headers={headers}
                  items={paymentOrders}
                  balancePermission={balancePermission}
                  storageKey={`invtReport-${company}`}
                />
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ModalOrderPayments
