import React, { useEffect } from 'react'

import { saveAs } from 'file-saver'
import { Row, Col } from 'react-bootstrap'
import { Paragraph, Card, Button } from 'src/components'
import {
  faDownload,
  faFileCode,
  faFilePdf,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes, getPublicDTE } from 'src/actions/public-invoices.action'
import { selectPublicDTE } from 'src/selectors/public-invoices.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { PublicLayout } from 'src/content/PublicDocument/PublicLayout'

export const PublicDocument = () => {
  const dispatch = useDispatch()

  const { uuid } = useParams() as { uuid: string }

  const dte = useSelector(selectPublicDTE)
  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_PUBLIC_INVOICES])(state),
  )

  const { amount, authDate, documentType, documents, data, ok } = dte

  useEffect(() => {
    dispatch(getPublicDTE(uuid))
  }, [])

  const downloadFile = async ({ value, label }: IInvoiceDataDocument) => {
    const typedArray = new Uint8Array(value)

    const file = new Blob([typedArray], {
      type: `application/${label}`,
    })

    saveAs(file, `${uuid}.${label}`)
  }

  const render = (
    <>
      <Card
        className={'left column'}
        style={{ backgroundColor: '#046394', color: 'white' }}>
        <Paragraph size={'extraBig'} style={{ color: 'white' }} className={'mb-2'}>
          Documento tributario electrónico: {documentType}
        </Paragraph>
        <Paragraph size={'big'} style={{ color: 'white' }} className={'mb-2'}>
          Total: {amount}
        </Paragraph>
        <Paragraph style={{ color: 'white' }}>Fecha de autorización {authDate}</Paragraph>
      </Card>

      <Card>
        {data.map(item => (
          <Row key={item.label}>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Paragraph bold>{item.label}</Paragraph>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className={'justify-content-end'}>
              <Paragraph>{item.value}</Paragraph>
            </Col>
          </Row>
        ))}
      </Card>

      <Row className={'container-buttons'}>
        {documents.map(item => {
          let icon: IconDefinition
          switch (item.label) {
            case 'xml':
              icon = faFileCode
              break
            case 'json':
              icon = faDownload
              break
            default:
              icon = faFilePdf
              break
          }

          return (
            <Button key={item.label} icon={icon} onClick={() => downloadFile(item)}>
              .{item.label}
            </Button>
          )
        })}
      </Row>
    </>
  )

  return (
    <PublicLayout
      title={'Verificador DTE'}
      description={'Visualización y descarga de documentos tributarios electrónicos'}
      loading={loading}
      isEmpty={!ok}
      empty={{
        title: 'No ha sido posible encontrar el documento indicado.',
        subtitle:
          'Por favor, verifique que el código de autorización esté correctamente escrito.',
      }}>
      {render}
    </PublicLayout>
  )
}
