import { combineReducers } from 'redux'
import loading from './loading.reducer'
import error from './error.reducer'
import dashboard from './dashboard.reducer'
import user from './user.reducer'
import warehouse from './warehouse.reducer'
import products from './products.reducer'
import address from './address.reducer'
import modules from './modules.reducer'
import company from './company.reducer'
import clients from './clients.reducer'
import uploads from './uploads.reducer'
import orders from './orders.reducer'
import payments from './payments.reducer'
import steps from './steps.reducer'
import contracts from './contracts.reducer'
import polygons from './polygons.reducer'
import resolutions from './resolutions.reducer'
import offers from './offers.reducer'
import cashBoxes from './cashbox.reducer'
import producer from './producer.reducer'
import disbursement from './disbursement.reducer'
import transformation from './transformation.reducer'
import enumeration from './enumeration.reducer'
import deposits from './deposit.reducer'
import shopping from './shopping.reducer'
import expenses from './expenses.reducer'
import utilities from './utilities.reducer'
import restaurant from './restaurant.reducer'
import categorization from './categorization.reducer'
import logs from './logs.reducer'
import versions from './versions.reducer'
import videos from './videos.reducer'
import balance from './balance.reducer'
import authorization from './authorization.reducer'
import notification from './notifications.reducer'
import configuration from './companyConfiguration.reducer'
import bank from './bank.reducer'
import purchase from './purchase.reducer'
import subscriptions from './subscriptions.reducer'
import token from './tokens.reducer'
import requests from './requests.reducer'
import accountbook from './accountbook.reducer'
import inventory from './inventory.reducer'
import parcel from './parcel.reducer'
import creditnote from './creditnote.reducer'
import report from './report.reducer'
import tickets from './tickets.reducer'
import account from './account.reducer'
import printers from './printer.reducer'
import plans from './plans.reducer'
import turns from './turn.reducer'
import currencies from './currencies.reducer'
import alert from './alert.reducer'
import transaction from './transaction.reducer'
import mentor from './mentor.reducer'
import books from './books.reducer'
import invoice from './invoice.reducer'
import dynamicFields from './companydynamicfields.reducer'
import taxes from './taxes.reducer'
import promotions from './promotions.reducer'
import publicInvoices from './public-invoices.reducer'
import cardProvider from './cardProvider.reducer'

const appReducer = combineReducers({
  loading,
  error,
  dashboard,
  user,
  warehouse,
  products,
  address,
  modules,
  company,
  clients,
  uploads,
  orders,
  payments,
  steps,
  contracts,
  polygons,
  resolutions,
  offers,
  cashBoxes,
  producer,
  disbursement,
  transformation,
  enumeration,
  deposits,
  shopping,
  expenses,
  utilities,
  restaurant,
  logs,
  categorization,
  versions,
  videos,
  balance,
  authorization,
  notification,
  configuration,
  bank,
  purchase,
  subscriptions,
  token,
  requests,
  accountbook,
  inventory,
  parcel,
  creditnote,
  report,
  tickets,
  account,
  printers,
  plans,
  turns,
  currencies,
  alert,
  transaction,
  mentor,
  books,
  invoice,
  dynamicFields,
  taxes,
  promotions,
  publicInvoices,
  cardProvider,
})

export const rootReducer = (state, action) => {
  const newState =
    action.type === 'CLEAR_COMPANY_STATE'
      ? {
          authorization: state.authorization,
          currencies: state.currencies,
          error: state.error,
          loading: state.loading,
          logs: state.logs,
          plans: state.plans,
          user: state.user,
          mentor: state.mentor,
          alert: state.alert,
          books: state.books,
          invoice: state.invoice,
          promotions: state.promotions,
        }
      : state

  return appReducer(newState, action)
}
