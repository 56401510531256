import React, { useEffect, useState } from 'react'

import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Button, Select, SwitchV2 } from 'src/components'

import { faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import NumberField from 'src/components/NumberField/NumberField'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { PolicyType } from 'src/content/Products/Policies/PolicyType'

interface DiscountPolicyProps {
  policy?: IDiscountPolicies
  onChange: (policy: IDiscountPolicies) => void
  onClose: () => void
  show: boolean
}

const policyTypes: ISelectNumber[] = [
  { value: PolicyType.PRICE, label: 'Precio' },
  { value: PolicyType.DISCOUNT, label: 'Descuento' },
]

export const DiscountPolicy = ({
  show,
  policy,
  onClose,
  onChange,
}: DiscountPolicyProps) => {
  const [data, setData] = useState<Partial<IDiscountPolicies>>({})

  const type: ISelectNumber = data.type
    ? policyTypes.find(p => p.value === data.type)
    : policyTypes[0]

  const isPricePolicy = PolicyType.PRICE === type.value

  useEffect(() => {
    if (!show) return
    setData(policy ?? {})
  }, [show])

  const onHide = () => {
    onClose()
    clear()
  }

  const clear = () => {
    setData({})
  }

  const onSave = () => {
    onChange({ ...(data as IDiscountPolicies), type: type.value })
    clear()
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Política de precios/descuentos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xl={12}>
              <Select
                label={'Tipo de política'}
                options={policyTypes}
                value={type}
                onChange={({ value }) =>
                  setData({ ...data, type: value, percentage: false })
                }
              />
            </Col>
            <Col xl={12} className={'mb-3'}>
              <div className={'d-flex'}>
                <NumberField
                  label={'Monto'}
                  value={data.amount}
                  onValueChange={amount => setData({ ...data, amount })}
                  decimals={2}
                  required
                  append={data.percentage ? '%' : undefined}
                  appendBefore
                  max={data.percentage ? 100 : undefined}
                  min={0}
                  containerStyle={{ width: isPricePolicy ? '100%' : '80%' }}
                />
                {!isPricePolicy && (
                  <SwitchV2
                    label={'%'}
                    checked={data.percentage}
                    info={''}
                    onChange={percentage => {
                      const newAmount = percentage
                        ? data.amount > 100
                          ? 100
                          : data.amount
                        : data.amount

                      setData({ ...data, percentage, amount: newAmount })
                    }}
                    inline
                    style={{
                      marginTop: '30px',
                      marginLeft: '5px',
                      width: '20%',
                    }}
                  />
                )}
              </div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <NumberField
                label={'Cantidad minima'}
                value={data.min}
                onValueChange={amount => setData({ ...data, min: amount })}
                decimals={2}
                required
              />
            </Col>
            <Col xl={6} lg={6} md={12} sm={12}>
              <NumberField
                label={'Cantidad maxima'}
                value={data.max}
                onValueChange={amount => setData({ ...data, max: amount })}
                decimals={2}
                required
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button icon={faWindowClose} color={'secondary'} onClick={onHide}>
            Cerrar
          </Button>
          <Button icon={faCheck} onClick={onSave} disabled={!haveAnyValue(data.amount)}>
            Guardar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
