import { $http } from './http'
import moment from 'moment'
import { saveAs } from 'file-saver'
import qs from 'qs'
import { formatDateFromMillis } from '../utils/formatters'

class WarehouseService {
  getWarehouses = async params => {
    const response = await $http.get(`/warehouses`, { params })
    return response.data
  }

  getAllWarehouses = async params => {
    const response = await $http.get(`/warehouses/company`, { params })
    return response.data
  }

  getUserWarehouses = async userId => {
    const response = await $http.get(`/warehouses/attendant/${userId}`)
    return response.data
  }

  getWarehousesUser = async () => {
    const response = await $http.get(`/warehouses/user`)
    return response.data
  }

  getExternalActiveWarehouses = async companyId => {
    const response = await $http.get(`/warehouses/active/external/${companyId}`)
    return response.data
  }

  getProducts = async () => {
    const response = await $http.get(`/products`)
    return response.data
  }

  getWarehousesTypes = async () => {
    const response = await $http.get(`/warehouses/types`)
    return response.data
  }

  getSingleWarehouse = async id => {
    const response = await $http.get(`/warehouses/${id}`)
    return response.data
  }

  updateWarehouse = async (id, request) => {
    const response = await $http.put(`/warehouses/${id}`, request)
    return response.data
  }

  createWarehouse = async request => {
    const response = await $http.post(`/warehouses`, request)
    return response.data
  }

  editMultipleExistenceWarehouse = async (
    fromWarehouseId,
    inventoryEditionRequestList,
    categorizationId,
  ) => {
    const response = await $http.post(`/inventory/batch`, {
      fromWarehouseId,
      inventoryEditionRequestList,
      categorizationId,
    })
    return response.data
  }

  addExistenceManualWarehouse = async (
    fromWarehouseId,
    productId,
    quantity,
    categorizationId,
  ) => {
    const response = await $http.post(`/inventory/manual/in`, {
      fromWarehouseId,
      productId,
      quantity,
      categorizationId,
    })
    return response.data
  }

  removeExistenceManualWarehouse = async (
    fromWarehouseId,
    productId,
    quantity,
    categorizationId,
  ) => {
    const response = await $http.post(`/inventory/manual/out`, {
      fromWarehouseId,
      productId,
      quantity,
      categorizationId,
    })
    return response.data
  }

  deleteWarehouse = async ids => {
    const response = await $http.delete(`/warehouses`, {
      params: {
        ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    return response.data
  }

  activeWarehouse = async id => {
    const response = await $http.put(`/warehouses/activate/${id}`)
    return response.data
  }

  getWarehouseInventory = async (warehouse, params) => {
    const response = await $http.get(`/inventory/existence/warehouse/${warehouse}`, {
      params,
    })
    return response.data
  }

  getWarehouseInventory2 = async (warehouse, params) => {
    const response = await $http.get(`/inventory/existence/warehouse2/${warehouse}`, {
      params,
    })
    return response.data
  }

  getWarehouseInventoryByOrder = async (warehouse, reserve) => {
    const response = await $http.get(
      `/inventory/existence/warehouse/order/${warehouse}?reserve=${reserve}`,
    )
    return response.data
  }

  createTransfer = async (
    fromWarehouseId,
    toWarehouseId,
    onConsignment,
    description,
    requested,
    detail,
    all,
    dispatchPending,
  ) => {
    const response = await $http.post(`/inventory/transfers/`, {
      fromWarehouseId,
      toWarehouseId,
      onConsignment,
      description,
      requested,
      detail,
      all,
      dispatchPending,
    })
    return response.data
  }

  createTransferByGroup = async (groupId, request) => {
    const response = await $http.post(`/inventory/transfers/group/${groupId}`, request)
    return response.data
  }

  createTransferWithoutProduct = async request => {
    const response = await $http.post(`/inventory/transfers/only/order`, request)
    return response.data
  }

  createTransferByOrder = async (orderId, request, reserved) => {
    const response = await $http.post(
      `/inventory/transfers/order/${orderId}?reserve=${reserved}`,
      request,
    )
    return response.data
  }

  createTransferInventory = async (
    fromWarehouseId,
    toWarehouseId,
    onConsignment,
    description,
    categories,
  ) => {
    const response = await $http.post(`/inventory/transfers/warehouses`, {
      fromWarehouseId,
      toWarehouseId,
      onConsignment,
      description,
      detail: null,
      categories,
    })
    return response.data
  }

  getTransfersTransformations = async (
    startDate,
    endDate,
    skip,
    size,
    external,
    recolect,
    state,
    search,
    warehouse,
    onlyTransfers,
    onlyTransformations,
    userId,
    documentType,
    withDetail,
    getTotal,
    documentId,
  ) => {
    const response = await $http.get(`/inventory/transfers`, {
      params: {
        onlyTransfers,
        onlyTransformations,
        startDate,
        endDate,
        skip,
        size,
        external,
        recolect,
        state,
        search,
        warehouse,
        userId,
        documentType,
        withDetail,
        getTotal,
        documentId,
      },
    })
    return response.data
  }

  getTransferDetail = async (id, withoutCompany) => {
    if (withoutCompany) {
      const response = await $http.get(`/notifications/transfers/${id}`)
      return response.data
    } else {
      const response = await $http.get(`/inventory/transfers/${id}`)
      return response.data
    }
  }

  approveTransferWithoutProduct = async id => {
    const response = await $http.put(`/inventory/transfers/approve/only/order/${id}`)
    return response.data
  }

  rejectTransferWithoutProduct = async id => {
    const response = await $http.put(`/inventory/transfers/reject/only/order/${id}`)
    return response.data
  }

  approveTransfer = async (id, params) => {
    const response = await $http.put(`/inventory/transfers/approve/${id}`, {}, { params })
    return response.data
  }

  approveTransferMassive = async (id, request) => {
    const response = await $http.post(
      `/inventory/transfers/approve/massive/${id}`,
      request,
    )
    return response.data
  }

  validateInventoryTransferMassive = async (id, request) => {
    const response = await $http.post(
      `/inventory/transfers/validate/massive/${id}`,
      request,
    )
    return response.data
  }

  rejectTransfer = async id => {
    const response = await $http.put(`/inventory/transfers/cancel/${id}`)
    return response.data
  }

  getProcessedOrders = async () => {
    const response = await $http.get(`/inventory/transfers/groups`)
    return response.data
  }

  getCategorizations = async () => {
    const response = await $http.get(`/inventory/categorizations`)
    return response.data.map(c => {
      return { value: c.id, label: c.name }
    })
  }

  getInventoryDetail = async params => {
    let paramst = '?'

    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramst += `${key}=${params[key]}&`
      }
    })

    const response = await $http.get(`/inventory/detail`.concat(paramst))
    return response.data
  }

  getTotalProductReport = async (sDate, eDate, distributor, deliverer, seller, data) => {
    const response = await $http.put(
      `/inventory/productsReport/${sDate}/${eDate}`,
      data,
      { params: { distributor, deliverer, seller } },
    )
    //const response = await $http.get(`/inventory/productsReport/${sDate}/${eDate}`, {params: {distributor, deliverer, seller}});
    return response.data
  }

  getExcelTotalProductReport = async (
    sDate,
    eDate,
    distributor,
    deliverer,
    seller,
    pos,
    request,
  ) => {
    const response = await $http.put(
      `/inventory/productsReport/excel/${sDate}/${eDate}`,
      request,
      { responseType: 'blob', params: { distributor, deliverer, seller, pos } },
    )

    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - Ventar totales - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getTotalProductAdminReport = async (sDate, eDate) => {
    const response = await $http.get(`/inventory/productsAdminReport/${sDate}/${eDate}`)
    return response.data
  }

  getAssociatedOrders = async id => {
    const response = await $http.get(`/associated/${id}`)
    return response.data
  }

  getInventoryWithPrices = async params => {
    const response = await $http.get(`/inventory/existence/price`, { params })
    return response.data
  }

  onValidateInventory = async (warehouse, type, request) => {
    const response = await $http.post(
      `/inventory/validate/${warehouse}?type=${type}`,
      request,
    )
    return response.data
  }

  getLastInventoryLog = async (warehouse, params) => {
    let queryParams = '?'
    Object.keys(params).forEach(key => {
      if (params[key] != null && params[key] !== undefined)
        queryParams += `${key}=${params[key]}&`
    })

    const response = await $http.get(
      `/inventory/validate/last/${warehouse}`.concat(queryParams),
    )
    return response.data
  }

  getPurchaseReport = async warehouse => {
    const response = await $http.get(
      `/inventory/purchase/report?${warehouse ? 'warehouse=' + warehouse : ''}`,
    )
    return response.data
  }

  getExcelByWarehouse = async (warehouse, params) => {
    const response = await $http.get(`/inventory/excel/warehouse/${warehouse}`, {
      responseType: 'blob',
      params,
    })
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - inventario - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getDetailedExcelByWarehouse = async (warehouse, params) => {
    const response = await $http.get(`/inventory/excel/detailed/warehouse/${warehouse}`, {
      responseType: 'blob',
      params,
    })
    const fileName = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : moment().format('LL') + ' - inventario desglosado - .xlsx'

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(file, fileName.replace('json', 'xlsx'))
  }

  getInventoryHistory = async (warehouse, params) => {
    let queryParams = '?'
    Object.keys(params).forEach(key => {
      if (params[key] != null && params[key] !== undefined)
        queryParams += `${key}=${params[key]}&`
    })
    const response = await $http.get(
      `/inventory/historical/${warehouse}`.concat(queryParams),
    )
    return response.data
  }

  loadWarehouses = async (warehouses, currentIndex) => {
    const response = await $http.post(`/warehouses/csv?index=${currentIndex}`, warehouses)
    //const response = { data: { items: warehouses, errors: [], ok: true } }
    return response.data
  }

  getInventoryReport = async (warehouseId, request, startDate, endDate, type) => {
    const params = { warehouseId, startDate, endDate, type }
    const response = await $http.put(`/inventory/report`, request, { params })
    return response.data
  }

  getGPSCoords = async (warehouseIds, ownWarehouse) => {
    try {
      const response = await $http.get(`/warehouses/location`, {
        params: { warehouseIds, ownWarehouse },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
      return response.data.items.map(coord => {
        const sp = coord.id.split(',')
        let id = sp[0],
          type = sp[1]
        return {
          id: Number(id),
          latitude: coord.pos.y,
          longitude: coord.pos.x,
          name: coord.nm,
          type,
        }
      })
    } catch (e) {
      return null
    }
  }

  getGPSWarehouse = async warehouseId => {
    const response = await $http.get(`/warehouses/location/${warehouseId}`)
    return response.data
  }

  setWailonIdWarehouse = async (warehouseId, params) => {
    const response = await $http.put(
      `/warehouses/location/${warehouseId}`,
      {},
      { params },
    )
    return response.data
  }

  changeKoloState = async warehouse => {
    const response = await $http.put(`/warehouses/kolo/${warehouse}`)
    return response.data
  }

  printQR = async warehouseId => {
    const response = await $http.get(`/warehouses/print/${warehouseId}`, {
      responseType: 'blob',
    })

    const today = new Date()
    const file = new Blob([response.data], { type: 'application/pdf' })
    const filename = response.headers['content-disposition']
      ? response.headers['content-disposition']
          .replace('attachment; filename="', '')
          .replace('"', '')
      : today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate() +
        ' - Bodega - ' +
        '.pdf'
    saveAs(file, filename.replace('json', 'pdf'))
  }

  changeKoloDeliveryState = async warehouse => {
    const response = await $http.put(`/warehouses/kolo/delivery/${warehouse}`)
    return response.data
  }

  updateAttendants = async request => {
    const response = await $http.put(`/warehouses/assign/attendants/`, request)
    return response.data
  }

  getWarehouseLite = async params => {
    const response = await $http.get('/warehouses/lite', { params })
    return response.data
  }

  exportWarehouseKardex = async (params, warehouseName) => {
    const response = await $http.get('/inventory/kardex/warehouse/export', {
      responseType: 'blob',
      params,
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel',
    })
    saveAs(
      file,
      `Kardex ${warehouseName ?? ''} ${formatDateFromMillis(new Date().valueOf())}.xlsx`,
    )
  }

  getItemsToSelect = async params => {
    const response = await $http.get('/inventory/to/select', { params })
    return response.data
  }

  getLineItemsToSelect = async (request, params) => {
    const response = await $http.post('/inventory/to/select/value', request, { params })
    return response.data
  }

  getTransferPDF = async (id, name, save = true) => {
    const response = await $http.get(`/inventory/transfers/${id}/pdf`, {
      responseType: 'blob',
    })
    const file = new Blob([response.data], {
      type: 'application/pdf',
    })

    if (save) saveAs(file, name)
    else return file
  }

  getPendingMissingRequest = async (warehouseId, params) => {
    const response = await $http.get(`/inventory/dispatch/${warehouseId}/pending`, {
      params,
    })
    return response.data
  }

  onUpdateStatusPendingMissingRequest = async (request, params) => {
    const response = await $http.put(`/inventory/dispatch/pending`, request, { params })
    return response.data
  }

  onCreateTransferMissingRequest = async (request, params) => {
    const response = await $http.put(`/inventory/dispatch/transfer`, request, { params })
    return response.data
  }

  getWarehouseInventoryVariation = async (warehouse, productId, params) => {
    const response = await $http.get(
      `/inventory/existence/warehouse/variation/${warehouse}/${productId}`,
      {
        params,
      },
    )
    return response.data
  }

  getWarehouseInventoryByVariations = async (warehouse, params) => {
    const response = await $http.get(
      `/inventory/existence/warehouse/variation/${warehouse}`,
      {
        params,
      },
    )
    return response.data
  }

  getMaxMinConfigurationToWarehouse = async warehouseId => {
    const response = await $http.get('/inventory/minMax', { params: { warehouseId } })
    return response.data
  }

  createMaxMinItemToWarehouse = async (warehouseId, items) => {
    const response = await $http.post('/inventory/minmax', items, {
      params: { warehouseId },
    })
    return response.data
  }

  updateMaxMinItemToWarehouse = async (warehouseId, items) => {
    const response = await $http.put('/inventory/minmax', items, {
      params: { warehouseId },
    })
    return response.data
  }

  onCreateDispatchVisits = async (request, params) => {
    const response = await $http.post('/inventory/dispatch/pending/visits', request, {
      params,
    })
    return response.data
  }

  getWarehouseKardexReport = async params => {
    const response = await $http.get('/inventory/kardex/report', {
      params,
    })
    return response.data
  }

  onValidateTransfer = async manufacturerId => {
    const response = await $http.put(`/inventory/transfers/${manufacturerId}/validate`)
    return response.data
  }

  syncKardexSpreadsheet = async warehouseId => {
    const response = await $http.put(
      `/warehouses/spreadsheet/sync/kardex?warehouseId=${warehouseId}`,
    )
    return response.data
  }
}

export default new WarehouseService()
