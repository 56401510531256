import { useEffect, useState } from 'react'
import { haveAnyValue } from 'src/utils/utilitiesV2'

export const useElapsedTime = (start: number, end?: number) => {
  const [time, setTime] = useState<number>(0)

  useEffect(() => {
    if (haveAnyValue(end)) {
      setTime(end - start)
    } else {
      const interval = setInterval(() => {
        setTime(new Date().valueOf() - start)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [start, end])

  return time
}
