export const PaymentType = {
  CASH: 1,
  CREDIT: 2,
  CREDIT_CARD: 3,
  BANK_TRANSFER: 4,
  CARD: 6,
  MULTIPLE: 7,
  LINK: 100,
}

const paymentTypesNames = {
  1: 'Efectivo',
  2: 'Crédito',
  4: 'Transferencia',
  6: 'Tarjeta',
  7: 'Múltiple',
}

const getPaymentTypes = (): ISelect[] => {
  const paymentFilter = [PaymentType.CREDIT_CARD, PaymentType.LINK]

  return Object.keys(PaymentType)
    .filter(type => !paymentFilter.includes(PaymentType[type]))
    .map(type => {
      const key = PaymentType[type]

      return {
        value: key,
        label: paymentTypesNames[key],
      }
    })
}

export { getPaymentTypes }
