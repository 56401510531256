import React from 'react'
import Placeholder from 'src/assets/images/newDesign/placeholder.svg'
import CustomCreate from 'src/components/inputs/Creable/CustomCreate'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCompanyFieldById,
  selectCompanyFieldEditPricePurchase,
  selectCompanyFieldProductRepeat,
} from 'src/selectors/company.selector'
import { LineQuantities } from 'src/components/Transaction/LineQuantities'
import { itemTypes } from 'src/utils/utilities'
import warehouseService from 'src/services/warehouse.service'
import { getUniversalItem } from 'src/utils/getUniversalItem'
import { ItemPrice } from 'src/components/Transaction/Row/ItemPrice'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { ItemDiscount } from 'src/components/Transaction/Row/ItemDiscount'
import Money from 'src/components/Money/Money'
import Button from 'src/components/buttons/Button'
import { CustomDate, Icon, NumberField, Switch } from 'src/components'
import { faBarcode, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import { selectConfigTransactionTable } from 'src/selectors/transaction.selector'
import { useLocation } from 'react-router-dom'
import { SerieItem } from 'src/components/Items/Series/SerieItem'
import { formatNumberWithCommas } from 'src/utils/formatters'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface Props {
  item: IItemSelectTable
  index: number
  itemsToSelect: IItemToSelectResponse[]
  selected: IItemSelectTable[]
  type: number
  isEdit: boolean
  warehouseId: number
  updateValues: () => void
  disableDiscount: boolean
  onChangeDiscount: (item: IItemSelectTable, value: number) => void
  onChangePercentDiscount: (item: IItemSelectTable, value: ISelect) => void
  discounts: ISelect[]
  onDiscardItem: () => void
  updateTax: (item: IItemSelectTable) => void
  locations: boolean
  currency: string
  hideItemImage: boolean
  style?: React.CSSProperties
  forDisplay?: boolean
  seeInputTax?: boolean
  balancePermission?: boolean
}

/**
 *
 * @param {IItemSelectTable} item // Item de la tabla seleccionado
 * @param {number} index // Indice del item
 * @param {IItemToSelectResponse[]} itemsToSelect // Items disponibles para seleccionar en selector
 * @param {IItemSelectTable[]} selected // Listado de Items seleccionados
 * @param {number} type // Tipo de transaccion
 * @param {boolean} isEdit // Si es edicion
 * @param {number} warehouseId // Id de la bodega
 * @param {Function} updateValues // Funcion para actualizar los valores
 * @param {boolean} disableDiscount // Deshabilitar descuento
 * @param {Function} onChangeDiscount // Cambio de descuento normal
 * @param {Function} onChangePercentDiscount // Cambio de descuento por porcentaje
 * @param {ISelect[]} discounts // Listado de Descuentos
 * @param {Function} onDiscardItem // Descartar item
 * @param {Function} updateTax // Actualizar impuesto
 * @param {boolean} locations // Item usa ubicacion
 * @param {string} currency // Moneda simbolo
 * @param {boolean} hideItemImage // Hide item image column
 * @param {React.CSSProperties} style // Style
 * @param {boolean} forDisplay // For display
 * @param {boolean} seeInputTax // For display
 * @param balancePermission
 * @returns
 */
export const ItemRow = ({
  item,
  index,
  itemsToSelect,
  selected,
  type,
  isEdit,
  warehouseId,
  updateValues,
  disableDiscount,
  onChangeDiscount,
  onChangePercentDiscount,
  discounts,
  onDiscardItem,
  updateTax,
  locations,
  currency,
  hideItemImage,
  style,
  forDisplay = false,
  seeInputTax = false,
  balancePermission = true,
}: Props) => {
  const { key, url } = item

  const dispatch = useDispatch()

  const fieldEditPrice = useSelector(selectCompanyFieldEditPricePurchase)
  const fieldRepeat = useSelector(selectCompanyFieldProductRepeat)
  const currentCurrency = useSelector(selectCurrentCurrency)
  const maxDiscountCompany = useSelector(state => selectCompanyFieldById(state, 89))
  const disabledDiscounts = useSelector(state => selectCompanyFieldById(state, 84))
  const generalConfigTable: IGeneralConfigTTable = useSelector(
    selectConfigTransactionTable,
  )
  const itemOnlyCost =
    type !== itemTypes.RECIPE_ITEMS && (fieldEditPrice || item.decimals)
  const importation = useLocation().pathname.includes('/importaciones')
  const transactionIn =
    type === itemTypes.PRODUCTION ||
    type === itemTypes.PURCHASE ||
    type === itemTypes.EXPENSE

  const isPurchaseExpense =
    type === itemTypes.PURCHASE ||
    type === itemTypes.EXPENSE ||
    type === itemTypes.RECIPE_ITEMS
  /**
   * Obtienen los items disponibles para seleccionar
   * @param {Array} data Lista de items a seleccionar
   * @returns array de items
   */
  const getItems = data => {
    if (fieldRepeat) return formatItems(data)
    const list = data.filter(d => d && selected.findIndex(s => s && s.id === d.id) === -1)
    return formatItems(list)
  }

  /**
   * Realiza la consulta al servicio para obtener el árbol de ítems del producto.
   * @param {IItemSelectTable} item Item seleccionado
   */
  const getItemValue = item => {
    dispatch(
      setItemTable({
        index: index,
      }),
    )
    dispatch(
      setGeneralConfig({
        showList: false,
        value: item.value,
        getItemValue: true,
      }),
    )
  }

  /**
   * Metodo para obtener los items por busqueda.
   * @param {string} search Busqueda por nombre
   * @returns
   */
  const setUp = async search => {
    const params = {
      warehouseId: warehouseId,
      withZero: true,
      withCategories: true,
      type,
      search,
      withExistence: false,
      allInventory: false,
    }
    const result: unknown[] = await warehouseService.getItemsToSelect(params)
    if (
      'UNIX'.includes(search?.toUpperCase()) ||
      'UNIVERSAL'.includes(search?.toUpperCase())
    )
      result.unshift(getUniversalItem())

    return result
  }

  const actionsSelect = (itemSelected, key) => {
    if (index >= 0) {
      const newValue =
        itemSelected.id !== 1
          ? Object.assign({}, itemSelected)
          : JSON.parse(JSON.stringify(itemSelected))
      selected[index] = {
        ...newValue,
        key,
        detailId:
          selected[index].detailId && isEdit ? selected[index].detailId : undefined,
        edited: selected[index].detailId && isEdit ? true : undefined,
      }

      const itemHaveVariations =
        selected[index].listCategorization && selected[index].listCategorization !== null

      if (selected[index].id !== 1) {
        if (
          type === itemTypes.PURCHASE ||
          type === itemTypes.PRODUCTION ||
          type === itemTypes.EXPENSE
        ) {
          if (itemHaveVariations) selected[index].showVariations = true
          else getItemValue(itemSelected)
        } else {
          if (newValue.inBatches || locations) {
            const params = {
              showList: true,
              value: selected[index].value,
              getVariationsValue: true,
              params: null,
            }
            if (newValue.inBatches)
              params.params = {
                type,
                purchaseSegmented: true,
              }
            dispatch(setGeneralConfig(params))
            dispatch(
              setItemTable({
                index,
                inBatches: newValue.inBatches,
              }),
            )
          } else if (itemHaveVariations) selected[index].showVariations = true
          else getItemValue(itemSelected)
        }
      } else if (itemSelected.value === 1) selected[index].commentaryShow = true

      modifyValues()
    }
  }

  /**
   * Da formato a los items para seleccionar
   * @param {Array} data Datos de items
   * @returns
   */
  const formatItems = data => {
    if (!data) return []
    return data.map(d => ({
      ...d,
      value: d.id,
      label: d.code + ' - ' + d.name,
      includeIva: true,
      productPrice: d.price,
      productId: d.id,
      lineItems: d.value === 1 ? d.lineItems : [],
    }))
  }

  const getSelect = (item, key) => {
    return (
      <CustomCreate
        //   disabled={disabled}
        mt={0}
        withoutLabel
        async
        dontClear
        onCreateOption={() => undefined}
        options={getItems(itemsToSelect)}
        isLoading={false}
        placeholder={'Selecciona un ítem'}
        textLabel={'Sin resultados: '}
        value={item}
        onChange={v => {
          actionsSelect(v, key)
        }}
        loadOptions={async searchItem => {
          if (!(searchItem === null || searchItem === undefined || searchItem === '')) {
            let data: unknown[] = await setUp(searchItem)
            data = getItems(data)
            // @ts-ignore
            itemsToSelect = [getUniversalItem(), ...data]
            return getItems(itemsToSelect)
          } else return getItems(itemsToSelect)
        }}
      />
    )
  }

  const updateSubTotal = (): number => {
    let sub = 0
    item.haveQuantities = false
    item.line.forEach(x => {
      if (x.quantity > 0 && x.ownPrice) {
        item.haveQuantities = true
        sub = sub + x.quantity * (x.ownPrice || 0)
      }
    })
    return Number(sub.toFixed(2))
  }

  const modifyValues = () => {
    if (isEdit && item.detailId) {
      item.edited = true
    }
    updateValues()
  }

  const boxExtraSizes = {
    [itemTypes.WASTE]: 0,
    [itemTypes.SELL]: 3,
    [itemTypes.PRODUCTION]: 1,
    [itemTypes.PURCHASE]: 3,
    [itemTypes.EXPENSE]: 2,
  }
  /**
   * Generates a determinated number of empty box in the table, this is useful
   * when the row in the table doesnt have a item yet.
   * @returns List of elements </td>
   */
  const setEmptyBox = () => {
    const numbers = boxExtraSizes[type]
    const tds = []
    for (let i = 0; i < numbers; i++) {
      tds.push(<td key={i} />)
    }
    return tds
  }

  return (
    <>
      <tr className={'data'} key={key} style={{ ...style }}>
        {!hideItemImage ? (
          <td className={'mini text-center'}>
            <div className={'t-item-img'}>
              <img src={url || Placeholder} alt={'img'} />
            </div>
          </td>
        ) : (
          <td />
        )}
        <LineQuantities
          select={getSelect(item, key)}
          currentItem={item}
          updateValues={(type: number) => {
            if (type === 0) updateValues()
            else modifyValues()
          }}
          type={type}
          maxDiscountCompany={Number(maxDiscountCompany.value)}
          updateTax={item => {
            if (isEdit && item.detailId) {
              item.edited = true
            }
            updateTax(item)
          }}
          index={index}
          useLocations={locations}
          fieldEditPrice={fieldEditPrice}
        />

        {item.id ? (
          <>
            {balancePermission &&
              [itemTypes.SELL, itemTypes.ADDONS].some(x => x === type) && (
                <>
                  <td>
                    <ItemPrice
                      item={item}
                      currentCurrency={currentCurrency}
                      onUpdate={() => {
                        modifyValues()
                      }}
                      ignorePermissions={[itemTypes.ADDONS].some(x => x === type)}
                    />
                  </td>
                  {disabledDiscounts?.value === 'false' && type === itemTypes.SELL && (
                    <td>
                      <ItemDiscount
                        disabled={disableDiscount}
                        discounts={discounts}
                        item={item}
                        onChangeDiscount={onChangeDiscount}
                        onChangePercentDiscount={onChangePercentDiscount}
                      />
                    </td>
                  )}

                  {[itemTypes.SELL, itemTypes.ADDONS].some(x => x === type) && (
                    <td>
                      <div className={'b-user-name mt-2'}>
                        <Money className={'short center'}>{item.subTotal}</Money>
                      </div>
                    </td>
                  )}
                </>
              )}
            {transactionIn && (
              <td>
                {item.line &&
                  item.line.map((itemLine, index) => (
                    <div
                      style={{
                        marginBottom: 13,
                        textAlign: 'center',
                        minWidth: 120,
                      }}
                      key={index}>
                      {itemOnlyCost || type === itemTypes.PRODUCTION ? (
                        <NumberField
                          disabled={itemLine.quantity === 0 || !itemLine.quantity}
                          style={{ width: '100%' }}
                          value={itemLine.ownPrice || 0}
                          decimals={4}
                          min={0}
                          onValueChange={value => {
                            if (item.individualTotal) {
                              itemLine.ownPrice = value
                              if (itemLine.quantity > 0) {
                                const sub = value * itemLine.quantity
                                itemLine.subTotal = Number(sub.toFixed(2))
                              }
                            } else {
                              itemLine.ownPrice = value
                              item.subTotal = updateSubTotal()
                            }
                            if (generalConfigTable.withOutIVA)
                              itemLine.purchasePrice =
                                Number(itemLine.ownPrice) -
                                Number(itemLine.ownPrice) *
                                  ((generalConfigTable.withOutIVA.value || 0) / 100)

                            modifyValues()
                          }}
                        />
                      ) : (
                        <div style={{ height: 40 }}>
                          {`${currency}. ` +
                            ((itemLine.ownPrice || 0).toFixed(2) || '0.00')}
                        </div>
                      )}
                    </div>
                  ))}
              </td>
            )}
            {(isPurchaseExpense || type === itemTypes.PRODUCTION) && (
              <>
                {type !== itemTypes.PRODUCTION && (
                  <td>
                    <div style={{ textAlign: 'center' }}>
                      {itemOnlyCost ? (
                        <div style={{ height: 40 }}>
                          {`${currency}.  ${(item.subTotal || 0).toFixed(2) || '0.00'}`}{' '}
                        </div>
                      ) : (
                        item.line &&
                        item.line.map((itemLine, index) => (
                          <div style={{ marginBottom: 13 }} key={index}>
                            <Row>
                              <Col>
                                {(item.individualTotal || index === 0) && (
                                  <NumberField
                                    disabled={
                                      itemLine.quantity === 0 || !itemLine.quantity
                                    }
                                    style={{ width: '100%' }}
                                    decimals={4}
                                    value={
                                      (item.individualTotal
                                        ? itemLine.subTotal
                                        : item.subTotal) || 0
                                    }
                                    min={0}
                                    onValueChange={value => {
                                      if (item.individualTotal) {
                                        if (itemLine.quantity > 0) {
                                          const price = value / itemLine.quantity
                                          itemLine.ownPrice = Number(price.toFixed(2))
                                        }
                                      }
                                      item.subTotal = value
                                      itemLine.subTotal = value

                                      if (generalConfigTable.withOutIVA.active)
                                        itemLine.purchasePrice =
                                          Number(itemLine.ownPrice) -
                                          Number(itemLine.ownPrice) *
                                            ((generalConfigTable.withOutIVA.value || 0) /
                                              100)

                                      modifyValues()
                                    }}
                                  />
                                )}
                              </Col>
                              {index === 0 &&
                                itemLine.presentationFactor !== null &&
                                type !== itemTypes.RECIPE_ITEMS && (
                                  <Row
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    style={{
                                      marginLeft: 10,
                                    }}>
                                    {' '}
                                    <Switch
                                      checked={item.individualTotal}
                                      label={' '}
                                      onChange={({ target }) => {
                                        const { checked } = target
                                        item.individualTotal = checked
                                        if (checked) {
                                          item.subTotal = 0
                                          if (importation) {
                                            item.tax = 0
                                            item.customPrice = 0
                                          }
                                        } else
                                          item.line.forEach(l => {
                                            l.ownPrice = 0
                                            l.subTotal = 0
                                            if (importation) {
                                              l.tax = 0
                                              l.customPrice = 0
                                            }
                                          })
                                        modifyValues()
                                      }}
                                      info={
                                        'Aplicar subtotales individuales a el árbol de ítems'
                                      }
                                    />
                                  </Row>
                                )}
                            </Row>
                          </div>
                        ))
                      )}
                    </div>
                  </td>
                )}
                {seeInputTax && type !== itemTypes.PRODUCTION && (
                  <td>
                    <NumberField
                      disabled={!haveAnyValue(item.subTotal)}
                      style={{ width: '100%' }}
                      decimals={4}
                      value={item.tax}
                      min={0}
                      onValueChange={value => {
                        if (value > item.subTotal) value = item.subTotal
                        item.tax = value
                        modifyValues()
                      }}
                    />
                  </td>
                )}

                {seeInputTax && (
                  <td>
                    {formatNumberWithCommas((item.subTotal || 0) + (item.tax || 0))}
                  </td>
                )}

                {[itemTypes.PURCHASE, itemTypes.PRODUCTION].some(x => x === type) && (
                  <td>
                    <div
                      style={{
                        width: 150,
                      }}>
                      <CustomDate
                        mt={0}
                        disabled={!item.value}
                        value={item.expirationDate}
                        disabledDays={[]}
                        onDayChange={d => {
                          item.expirationDate = d
                          modifyValues()
                        }}
                      />
                    </div>
                  </td>
                )}

                {type === itemTypes.PURCHASE && !importation && (
                  <SerieItem
                    toSelect={item}
                    changeItem={() => modifyValues()}
                    modal
                    type={type}
                    importation={importation}
                    onAdd={() => {
                      modifyValues()
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>{setEmptyBox()}</>
        )}
        {
          // TODO: Add fields of purchase importation
        }
        {!forDisplay && (
          <td>
            <div>
              {type === itemTypes.PURCHASE && !importation && item.decimals === null && (
                <Button
                  color={'accent'}
                  onClick={() => {
                    item.showSeries = true
                    updateValues()
                  }}>
                  <Icon tooltip={'Números de Serie'} icon={faBarcode} />
                </Button>
              )}
              <Button onClick={() => onDiscardItem()}>
                <Icon color={'white'} icon={faTrash} tooltip={'Descartar'} />
              </Button>
            </div>
          </td>
        )}
      </tr>
    </>
  )
}
