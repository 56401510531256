import './POS.scss'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, Select, Button, ListCategories } from 'src/components'
import ImageInput, { imageTypes } from 'src/components/inputs/ImageInput/ImageInput'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite } from 'src/selectors/warehouse.selector'

import {
  actionTypes as typeT,
  createTerminal,
  deleteTable,
  updateTerminal,
} from 'src/actions/restaurant.actions'

import {
  selectCompanyIsLimit,
  selectSetCurrentCompany,
} from 'src/selectors/company.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { terminalTypes } from 'src/enums/terminalTypes'
import Folder, { categoryType } from 'src/components/folders/Folder'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { getAllCategorizations } from 'src/actions/categorization.actions'

const POSTerminal = ({
  show,
  id,
  title,
  posId,
  onHide,
  warehouseId,
  number,
  type,
  free,
  posType = {},
  categories = [],
}) => {
  const dispatch = useDispatch()

  const emptyTerminal = {
    src: { url: null },
    type: terminalTypes[0],
    categories: [],
  }

  const warehouses = useSelector(selectWarehouseLite)
  const isLimit = useSelector(selectCompanyIsLimit)
  const company = useSelector(selectSetCurrentCompany)

  const categoriesToSelect = useSelector(selectAllCategorizations)

  const loadingCreateUpdate = useSelector(state =>
    loadingSelector([typeT.UPDATE_TERMINAL_POS])(state),
  )
  const hasErrorCreateUpdate = useSelector(state =>
    hasErrors([typeT.UPDATE_TERMINAL_POS])(state),
  )

  const loadingDelete = useSelector(state => loadingSelector([typeT.DELETE_TABLE])(state))
  const hasErrorDelete = useSelector(state => hasErrors([typeT.DELETE_TABLE])(state))

  const disabled = loadingCreateUpdate || loadingDelete

  const [flags, setFlags] = useState({ create: false, update: false, delete: false })
  const [alert, setAlert] = useState({ title: '' })

  const [open, setOpen] = useState(false)
  const [terminal, setTerminal] = useState(emptyTerminal)

  const [showCategorization, setShowCategorization] = useState(false)

  useEffect(() => {
    if (!show) return
    dispatch(getAllCategorizations(categoryType.POS_TERMINAL.id))
    setOpen(true)
    if (warehouses.length === 0) dispatch(getWarehouseLite())
    if (id) {
      setTerminal({
        id,
        src: { url: null },
        number,
        warehouse: onSetWarehouse(warehouses, true),
        type: terminalTypes.find(t => t.value === type),
        categories: categories,
      })
    }
  }, [show])

  useEffect(() => {
    if (warehouses.length === 0) return
    if (show && id && !terminal.warehouse && warehouseId) {
      onSetWarehouse(warehouses)
    }
  }, [warehouses.length])

  useEffect(() => {
    if (loadingCreateUpdate) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      setOpen(false)
      if (hasErrorCreateUpdate)
        setAlert({
          ...handlerError(hasErrorCreateUpdate.message),
          onConfirm: () => {
            setOpen(true)
            setAlert({ ...alert, show: false })
          },
        })
      else {
        setAlert({
          ...handlerSuccess(),
          showCancelButton: true,
          cancelButtonText: 'Cerrar',
          confirmButtonText: 'Seguir creando',
          confirmButtonColor: '#226095',
          onCancel: () => onClose(true),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setTerminal(emptyTerminal)
            setOpen(true)
          },
        })
      }
    }
  }, [loadingCreateUpdate])

  useEffect(() => {
    if (loadingDelete) setFlags({ ...flags, delete: true })
    else if (flags.delete) {
      setFlags({ ...flags, delete: false })

      let newAlert
      let onConfirm = () => setAlert({ ...alert, show: false })
      if (hasErrorDelete) newAlert = handlerError(hasErrorDelete.message)
      else newAlert = handlerSuccess('Terminal eliminada con éxito')

      setAlert({
        ...newAlert,
        onConfirm: () => {
          onConfirm()
          if (!hasErrorDelete) {
            onClose(true)
          }
        },
      })
    }
  }, [loadingDelete])

  const onSetWarehouse = (ws, get) => {
    const w = ws.find(w => w.value === warehouseId)
    if (get) return w
    else setTerminal({ ...terminal, warehouse: w })
  }

  const onClose = success => {
    setAlert({ ...alert, show: false })
    setTerminal(emptyTerminal)
    setOpen(false)
    onHide(success)
  }

  const onAssignCategorization = category => {
    const categories = Object.assign([], terminal.categories || [])
    const index = categories.findIndex(c => c.id === category.id)
    if (index === -1) categories.push(category)
    else categories.splice(index, 1)
    setTerminal({ ...terminal, categories })
  }

  return (
    <div>
      <Modal show={open} size={'lg'} centered onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl={6} md={6} sm={12}>
              <ImageInput
                imageType={imageTypes.POS_TERMINAL}
                src={terminal.src}
                placeholder={posType.image}
                disabled={disabled}
                entityId={id}
                onSuccessFile={url => {
                  setTerminal({ ...terminal, src: { url } })
                }}
              />
            </Col>
            <Col xl={6} md={6} sm={12}>
              <Row>
                <Col xl={12}>
                  <ListCategories
                    label={'Categorías'}
                    items={terminal.categories}
                    disabled={disabled}
                    onRemove={onAssignCategorization}
                    addItem={() => setShowCategorization(true)}
                    seeAddItem={!disabled}
                  />
                </Col>
                <Col xl={12}>
                  <FormText
                    disabled={disabled}
                    label={'Nombre (opcional)'}
                    value={terminal.number}
                    changeValue={number => setTerminal({ ...terminal, number })}
                  />
                </Col>
                <Col xl={12}>
                  <Select
                    disabled={!free || disabled}
                    label={'Tipo'}
                    options={terminalTypes}
                    value={terminal.type}
                    onChange={type => setTerminal({ ...terminal, type })}
                    required
                    subText={
                      !free &&
                      'No es posible editar el tipo de la terminal ya que la terminal está ocupada.'
                    }
                  />
                </Col>
                {!isLimit && (
                  <Col xl={12}>
                    <Select
                      disabled={!free || disabled}
                      label={'Bodega asignada'}
                      options={warehouses}
                      value={terminal.warehouse}
                      onChange={warehouse => setTerminal({ ...terminal, warehouse })}
                      required
                      subText={
                        !free &&
                        'No es posible editar la bodega de la terminal ya que la terminal está ocupada.'
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row className={'container-buttons'}>
            {id && (
              <Button
                loading={loadingDelete}
                disabled={loadingCreateUpdate}
                icon={faTrash}
                color={'secondary'}
                onClick={() => {
                  if (!free)
                    setAlert({
                      ...handlerError(
                        'No es posible eliminar la terminal ya que está ocupada',
                      ),
                      onConfirm: setAlert({ ...alert, show: false }),
                    })
                  else dispatch(deleteTable(id))
                }}>
                Eliminar
              </Button>
            )}

            <Button
              loading={loadingCreateUpdate}
              disabled={(!terminal.warehouse && !isLimit) || loadingDelete}
              icon={faSave}
              onClick={() => {
                const request = {
                  capacity: 1,
                  warehouse: isLimit ? company.warehouse : terminal.warehouse.value,
                  type: terminal.type.value,
                  posId,
                  tableName: terminal.number,
                  url: terminal.src.url,
                  categories: terminal.categories.map(c => c.id),
                }

                if (!id) {
                  dispatch(createTerminal(request))
                } else {
                  dispatch(updateTerminal(id, request))
                }
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Folder
        show={showCategorization}
        type={categoryType.POS_TERMINAL}
        list={terminal?.categories?.map(c => c.id)}
        noMessage
        onHide={() => setShowCategorization(false)}
        data1={categoriesToSelect?.children?.[0] ?? {}}
        data2={categoriesToSelect?.children?.[1] ?? {}}
        onAssign={onAssignCategorization}
      />

      <Alert {...alert} />
    </div>
  )
}
export default POSTerminal
