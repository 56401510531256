export enum CardPaymentStatus {
  ENTERED = 1,
  EXPIRED = 2,
  ACCEPTED = 3,
  REJECTED = 4,
}

export const cardPaymentStatusName = {
  [CardPaymentStatus.ENTERED]: 'PENDIENTE DE PROCESAR',
  [CardPaymentStatus.EXPIRED]: 'LINK EXPIRADO',
  [CardPaymentStatus.ACCEPTED]: 'PAGO ACEPTADO',
  [CardPaymentStatus.REJECTED]: 'PAGO RECHAZADO',
}
