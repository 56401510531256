import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Modal, Row } from 'react-bootstrap'
import { Button, FormText, Icon, Paragraph, Loading } from 'src/components'

import { faCopy, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { haveAnyValue } from 'src/utils/utilitiesV2'

import {
  actions,
  onCreateLinkPayment,
  setCardLinkPaymentRequest,
} from 'src/actions/cardProvider.actions'
import {
  selectCardLinkPayment,
  selectCardLinkRequest,
} from 'src/selectors/cardProvider.selector'
import { loadingSelector } from 'src/selectors/loading.selector'
import { formatDateFromMillis } from 'src/utils/formatters'
import { showAlert } from 'src/actions/alert.actions'
import { handlerError } from 'src/selectors/error.selector'

export const GenerateLinkPayment = () => {
  const dispatch = useDispatch()

  const request = useSelector(selectCardLinkRequest)
  const id = request.documentId
  const code = request.documentCode

  const init: boolean = haveAnyValue(id)

  const [isCopy, setCopy] = useState(false)

  const response = useSelector(selectCardLinkPayment)

  const loading = useSelector(state =>
    loadingSelector([actions.CREATE_LINK_PAYMENT])(state),
  )

  useEffect(() => {
    if (!(haveAnyValue(id) && init)) return
    setCopy(false)
    dispatch(onCreateLinkPayment(id))
  }, [id, init])

  const onClose = () => {
    dispatch(
      setCardLinkPaymentRequest({
        documentId: undefined,
        documentCode: undefined,
      }),
    )
  }

  return (
    <Modal show={init} centered>
      <Modal.Header>
        <Modal.Title>Link de pago: {code}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading show label={'Generando link, espere un momento.'} />
        ) : haveAnyValue(response?.url) ? (
          <Row>
            <Col xl={12}>
              <FormText
                disabled
                value={response.url}
                label={'Link de pago'}
                append={
                  <Icon
                    icon={faCopy}
                    tooltip={isCopy ? 'Link Copiado en el parta-papeles' : 'Copiar link'}
                    onClick={() => {
                      const alert = () =>
                        dispatch(
                          showAlert(
                            handlerError(
                              'Parece que hubo un problema al copiar el link. ',
                            ),
                          ),
                        )

                      if (navigator.clipboard) {
                        navigator.clipboard
                          .writeText(response.url)
                          .then(() => setCopy(true))
                          .catch(alert)
                      } else alert()
                    }}
                  />
                }
              />
            </Col>

            <Col xl={12}>
              <Paragraph bold className={'center'}>
                Tiempo de expiración
              </Paragraph>
            </Col>

            <Col xl={12}>
              <Paragraph className={'center'}>
                {formatDateFromMillis(response.date)}
              </Paragraph>
            </Col>
          </Row>
        ) : (
          'Sin resultados'
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className={'container-buttons'}>
          <Button color={'secondary'} icon={faWindowClose} onClick={onClose}>
            Cerrar
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
