import { toMoney } from 'src/utils/utilities'

export const getPaymentData = (voucher: ICardVoucherDTO): ISelectString[] => {
  return [
    { label: 'Número de transacción', value: voucher.transaction },
    { label: 'Titular de la tarjeta', value: voucher.name },
    { label: 'Tarjeta utilizada', value: `**** **** **** ${voucher.card}` },
    {
      label: 'Total pagado',
      value: `${voucher.currency} ${toMoney(voucher.amount, true)}`,
    },
    {
      label: 'Pasarela de pago',
      value: `PAGALO`,
    },
  ]
}
