import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col } from 'react-bootstrap'

import {
  Switch,
  CustomDate as DatePicker,
  Button,
  FormText as FormTextField,
  Icon,
  Select,
  FormText,
  Card,
  TableV2,
  Paragraph,
} from 'src/components'
import Gallery, { imageTypes } from 'src/components/gallery/Gallery'
import Folder, { categoryType } from 'src/components/folders/Folder'

import { parseDate } from 'react-day-picker/moment'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'

import { getAccounts } from 'src/actions/banks.actions'

import {
  actionTypes as typeC,
  getAllCategorizations,
} from 'src/actions/categorization.actions'
import { selectAllCategorizations } from 'src/selectors/categorizations.selector'
import { isAllowed } from 'src/selectors/modules.selector'

import { actionTypes as typesO, getPaymentReceipt } from 'src/actions/orders.actions'
import { selectPaymentReceipt } from 'src/selectors/orders.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { selectVouchersToPayment } from 'src/selectors/clients.selector'
import {
  actionTypes as cTypes,
  getVouchersByCustomersToPayment,
} from 'src/actions/clients.actions'
import Money from 'src/components/Money/Money'
import { toMoney } from 'src/utils/utilities'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import {
  selectCompanyCountry,
  selectCompanyFieldById,
} from 'src/selectors/company.selector'
import { getCompanyField } from 'src/actions/company.actions'
import { SelectClient } from 'src/components'

import { Country } from 'src/enums/countries'
import { PaymentType } from 'src/enums/paymentTypes'
import { BankDetails } from 'src/content/Orders/OrderCreate/BankDetails'

let interval = null
let d = new Date()
d.setDate(d.getDate() - 31)

const CreateOrderPayment = ({
  show,
  clientId,
  loading,
  onCreateOrder,
  client,
  balance,
  hideDate,
  multiple,
  orders,
  onAmountChange,
  billPayments,
  notCxc,
  isQuote,
}) => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)
  const valReceipt = useSelector(selectPaymentReceipt)

  const categorization = useSelector(selectAllCategorizations)
  const loadingAssigned = useSelector(state =>
    loadingSelector([typeC.GET_ASSIGNED, typeC.GET_ALL])(state),
  )

  const loadingValR = useSelector(state =>
    loadingSelector([typesO.GET_PAYMENT_RECEIPT])(state),
  )

  const vouchers = useSelector(selectVouchersToPayment)

  const loadingV = useSelector(state =>
    loadingSelector([cTypes.GET_VOUCHERS_CLIENT_TO_PAYMENT])(state),
  )

  const automaticFel = useSelector(state => selectCompanyFieldById(state, 117))
  const amountA = useSelector(state => isAllowed(state, [12051]))
  const currentCurrency = useSelector(selectCurrentCurrency)

  const [state, setState] = useState({
    errors: {},
    amount: null,
    paymentDate: parseDate(moment()),
    minDate: parseDate(moment()),
    receipt: null,
    total: 0,
    images: [],
    paymentType: { value: 1, label: 'Efectivo' },
    automaticA: false,
    certificateReceipt: null,
    selected: [],
    certificate:
      billPayments && (automaticFel.value === 'true' || automaticFel.value === '1'),
    transferDetail: {},
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCategoriesOrder, setCategoriesOrder] = useState([])
  const [showCategorization, setShowCategorization] = useState({ show: false })
  const [paymentOrders, setPaymentOrders] = useState([])
  const [clientOrder, setClientOrder] = useState(client)
  const [clientBalance, setClientBalance] = useState(balance)

  useEffect(() => {
    if (!show) return
    dispatch(getAccounts(false))
    dispatch(getAllCategorizations(17))
    dispatch(getCompanyField(94))
    if (!automaticFel.id) dispatch(getCompanyField(117))
    if (orders) setPaymentOrders(orders)
  }, [show])

  const onChange = event => {
    const { value, name } = event.target
    setState({ ...state, [name]: value })
    if (onAmountChange && name === 'amount') onAmountChange(Number(value))
  }

  const getAmount = total => {
    let t = -total || 0
    if (state.selected)
      state.selected.forEach(s => {
        t += s.total
      })
    if (!total) t = -t
    return -t
  }

  const headers = [
    { show: true, label: '' },
    { show: true, label: 'Tipo' },
    { show: true, label: 'Cliente' },
    { show: true, label: 'Número' },
    { show: true, label: 'Responsable' },
    { show: true, label: 'Referencia' },
    { show: true, label: 'Total' },
  ]

  const certificateReceiptTypes = [
    { value: null, label: 'Sin documento' },
    { value: 3, label: 'Factura electrónica' },
    { value: 4, label: 'Crédito fiscal', show: country.id === Country.SV },
    { value: 1, label: 'Recibo normal', show: country.id === Country.GT },
    { value: 2, label: 'Recibo de donación', show: country.id === Country.GT },
  ]

  const onDateChange = date => {
    setState({ ...state, paymentDate: date })
  }

  const changeAmountItem = (index, value) => {
    let array = paymentOrders
    array[index] = { ...array[index], amount: Number(value) }
    setPaymentOrders([...array])
  }

  const valPayment = () => {
    if (!paymentOrders) return
    return !(paymentOrders.filter(s => s.amount <= 0).length === 0)
  }

  const getMultipleAmount = () => {
    let response = 0
    if (paymentOrders) paymentOrders.map(m => (response += Number(m.amount)))
    return response
  }

  const validate = () => {
    let {
      amount,
      paymentDate,
      total,
      images,
      receipt,
      paymentType,
      automaticA,
      selected,
      certificate,
      certificateReceipt,
      transferDetail,
    } = state
    let errors = {}

    if (paymentType?.value === 9) amount = getAmount()

    if (paymentType.value === PaymentType.BANK_TRANSFER) {
      let balancePayment
      if (multiple) {
        balancePayment = getMultipleAmount()
      } else balancePayment = amount ? Number.parseFloat(amount) : 0

      if (!transferDetail?.bank) errors.amount = 'Es necesario seleccionar un banco'
      if (!transferDetail?.number)
        errors.amount = 'Es necesario ingresar un número de referencia válido'

      if (
        Number.parseFloat(total).toFixed(2) > Number.parseFloat(balancePayment).toFixed(2)
      )
        errors.total =
          'El saldo a depositar es mayor al pago por: ' +
          toMoney(Number.parseFloat(total) - balancePayment)
    }

    if (!amount && !multiple) errors.amount = 'Es necesario saldo a pagar'
    if (Object.keys(errors).length === 0) {
      let t = 0,
        extra = 0,
        notes = []
      if (selected.length > 0)
        selected.forEach(d => {
          let value = t + d.total
          if (value > clientBalance) {
            extra = value - clientBalance
            notes.push({ total: d.total - extra, id: d.id })
            t = clientBalance
          } else {
            notes.push({ total: d.total, id: d.id })
            t = value
          }
        })
      else t = parseFloat(amount)
      const payment = {
        paymentType: paymentType.value,
        paymentDate: moment(paymentDate).valueOf(),
        client: clientOrder?.id,
        amount: t,
        extra,
        payments: multiple
          ? paymentOrders.map(item => ({
              orderId: item.id,
              amount: item.amount,
            }))
          : null,
        deposit: null,
        receipt,
        categories: selectedCategoriesOrder.map(s => s.id),
        automatic: automaticA || clientOrder?.id === 0,
        selected: notes,
        certificate,
        certificateReceipt,
      }

      if (paymentType.value === PaymentType.BANK_TRANSFER) {
        payment.deposit = {
          ...transferDetail,
          date: transferDetail.date
            ? moment(transferDetail.date).valueOf()
            : new Date().valueOf(),
          own: true,
          message: 'Pago con trasferencia',
          categories: selectedCategories.map(s => s.id),
          automatic: automaticA,
          receipt,
          amount,
        }
        payment.images = images
        setSelectedCategories([])
      }

      onCreateOrder(payment)
      setCategoriesOrder([])
    }
    setState({ ...state, errors })
  }

  return (
    <div>
      <Paragraph size={'extraBig'}>
        Saldo pendiente: {toMoney(clientBalance, false, 0, true)}
      </Paragraph>
      <br />
      <Row>
        {notCxc && (
          <Col md={12}>
            <SelectClient
              actionType={cTypes.GET_CLIENT_POLYGON}
              label={'Cliente'}
              onChange={newClient => {
                setClientOrder(newClient)
                setClientBalance(newClient.balance)
              }}
              value={clientOrder}
              info={'Filtra las ordenes por el cliente seleccionado'}
              noCreate
              showAllOption
              allClients
              init
            />
          </Col>
        )}
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            label={'Tipo de pago'}
            options={[
              { value: 1, label: 'Efectivo' },
              { value: 4, label: 'Depósito o Transferencia' },
              { value: 6, label: 'Tarjeta de Crédito' },
              { value: 9, label: 'Vale o nota de crédito' },
            ]}
            value={state.paymentType}
            onChange={e => {
              setState({
                ...state,
                paymentType: e,
                show: false,
                selected: [],
                automaticA: e.value === 9,
              })
              if (e.value === 9) {
                dispatch(
                  getVouchersByCustomersToPayment({
                    use: 1,
                    clientId: notCxc ? clientOrder?.id : clientId,
                    isByClient: true,
                  }),
                )
              }
            }}
            subText={
              state.paymentType?.value === 9 &&
              'Se usara la totalidad de los vales o Notas de crédito, si el monto sobrepasa la cuenta por cobrar se creara un vale con el valor sobrante'
            }
          />
        </Col>

        {!multiple && state.paymentType.value !== 9 && (
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <FormTextField
              disabled={loading}
              id={'amount'}
              label={'Cantidad a abonar'}
              placeholder={`pj. ${toMoney(100)}`}
              type={'number'}
              name={'amount'}
              value={state.amount}
              onChange={onChange}
              required
              sub={
                state.amount > clientBalance &&
                'El excedente se convertirá en un vale que el cliente puede utilizar en sus próximas ordenes.'
              }
              error={state.errors.amount}
              prependMoneySymbol={state.paymentType.value !== PaymentType.BANK_TRANSFER}
            />
          </Col>
        )}

        {state.paymentType.value === PaymentType.BANK_TRANSFER && (
          <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
            <Row>
              <Col xl={12}>
                <BankDetails
                  onUpdate={transferDetail => {
                    setState({ ...state, transferDetail })
                  }}
                  allFields
                  active
                />
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <Row className={'pl-1'}>
                  {selectedCategories.map(p => (
                    <div className={'user-tag product-t ml-2'} key={p.id}>
                      <label className={'label-user-tag'}>{p.name}</label>
                      <Icon
                        className={'delete-user-tag d-product-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() =>
                          setSelectedCategories(
                            selectedCategories.filter(f => f.id !== p.id),
                          )
                        }
                      />
                    </div>
                  ))}
                  <Button
                    style={{ marginLeft: 15 }}
                    color={'primary'}
                    onClick={() => {
                      setShowCategorization({
                        ...showCategorization,
                        show: true,
                        isOrder: false,
                      })
                    }}>
                    Categorías de deposito
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        )}

        {!hideDate && (
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <DatePicker
              disabled={loading}
              label={'Seleccionar fecha de pago'}
              value={state.paymentDate}
              onDayChange={onDateChange}
              disabledDays={{ after: null }}
              format={'LL'}
              required
            />
          </Col>
        )}
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <FormTextField
            disabled={loading}
            id={'receipt'}
            label={'No. de Recibo'}
            placeholder={'pj. 12345'}
            name={'receipt'}
            value={state.receipt}
            error={
              state.receipt && valReceipt
                ? 'Nota: El número de referencia ya fue ingresado anteriormente.'
                : null
            }
            onChange={({ target }) => {
              const { value } = target
              setState({ ...state, receipt: value })
              if (value !== null && value !== undefined && value !== '') {
                clearTimeout(interval)
                interval = setTimeout(() => {
                  dispatch(getPaymentReceipt(target.value))
                }, 750)
              }
            }}
          />
        </Col>
        {(!isQuote || (isQuote && billPayments)) && (
          <>
            <Col
              md={
                state.certificateReceipt === 3 || state.certificateReceipt === 4 ? 8 : 12
              }>
              <Select
                label={'Tipo de documento legal'}
                options={certificateReceiptTypes}
                value={certificateReceiptTypes.find(
                  type => type.value === state.certificateReceipt,
                )}
                onChange={value => {
                  setState({
                    ...state,
                    certificateReceipt: value.value,
                  })
                }}
              />
              <br />
            </Col>
            {(state.certificateReceipt === 3 || state.certificateReceipt === 4) && (
              <Col xs={4}>
                <br />
                <Switch
                  topLabel
                  checked={state.certificate}
                  label={'Certificar documento'}
                  info={
                    'Si la opción no está activa no se generará un documento legal, únicamente un documento de empresa'
                  }
                  onChange={({ target }) => {
                    const { checked } = target
                    setState({ ...state, certificate: checked })
                  }}
                />
              </Col>
            )}
          </>
        )}
        {state.paymentType?.value === 9 && (
          <>
            <Col xl={4} md={6} sm={12}>
              <FormText
                label={'Saldo disponible para acreditación'}
                prependMoneySymbol
                value={getAmount(clientBalance)}
                disabled
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <TableV2
                items={vouchers?.vouchers || []}
                loading={loadingV}
                customClass={'scroll-x-without-height'}
                headers={headers}
                renderRow={(item, index) => (
                  <tr className={'data'} key={index}>
                    <td className={'mini'}>
                      <input
                        type={'checkbox'}
                        disabled={
                          state.selected.findIndex(f => f.id === item.id) === -1 &&
                          getAmount(clientBalance) < 0
                        }
                        name={index}
                        checked={state.selected.findIndex(f => f.id === item.id) >= 0}
                        onChange={() => {
                          if (state.selected.findIndex(f => f.id === item.id) >= 0)
                            state.selected = [
                              ...state.selected.filter(d => d.id !== item.id),
                            ]
                          else state.selected.push(item)
                          setState({ ...state })
                        }}
                      />
                    </td>
                    <td className={'text-left'}>
                      {item.documentType === 3 ? 'Nota de crédito' : 'Vale'}
                    </td>
                    <td className={'text-left'}>
                      {item.clientId ? item.clientData.companyName : 'Liberado'}
                    </td>
                    <td className={'text-left'}>{item.number}</td>
                    <td className={'text-left'}>
                      {item.responsibleId ? item.responsibleData.name : 'Desconocido'}
                    </td>
                    <Money className={'text-left'}>
                      {formatNumberWithCommas(item.total)}
                    </Money>
                  </tr>
                )}
                noItemsLegend={`No se encontraron vales o notas de crédito`}
                footerText={
                  <Money className={'b-user-name'}>Saldo a abonar: {getAmount()}</Money>
                }
              />
            </Col>
          </>
        )}
        {multiple && (
          <Col md={12}>
            <div className={'column'}>
              <TableV2
                customClass={'scroll-x-without-height'}
                headers={[
                  { label: 'Código', show: true },
                  { label: 'Fecha', show: true },
                  { label: `Total (${currentCurrency.symbol})`, show: true },
                  { label: 'Abono', show: true },
                ]}
                items={paymentOrders}
                renderRow={(item, i) => (
                  <tr key={i} className={'data'}>
                    <td className={'mini'}>{item.number}</td>
                    <td className={'mini'}>{formatDateFromMillis(item.date)}</td>
                    <td>{toMoney(item.balance, false, 0, true)}</td>
                    <td style={{ minWidth: 150 }}>
                      <FormText
                        placeholder={`pj. ${100}`}
                        type={'number'}
                        name={'amount'}
                        prependMoneySymbol
                        value={item.amount}
                        mt={0}
                        onChange={({ target }) => {
                          let { value } = target
                          if (!value || value < 0) value = 0
                          changeAmountItem(i, value)
                        }}
                      />
                    </td>
                  </tr>
                )}
                footerText={
                  <div className={'column'}>
                    <Money className={'b-user-name'}>
                      Total abonado: {getMultipleAmount()}
                    </Money>
                    {state.paymentType?.value === 9 &&
                      getMultipleAmount() > getAmount() && (
                        <div className={'b-user-email red'}>
                          {'El saldo a abonar es mayor a los montos de los vales | N/C seleccionados. Por: ' +
                            toMoney(Number.parseFloat(getMultipleAmount()) - getAmount())}
                        </div>
                      )}
                  </div>
                }
              />
            </div>
          </Col>
        )}
        <Col xl={12} lg={12} sm={12}>
          <Row className={'pl-1 my-1'}>
            {selectedCategoriesOrder.map(p => (
              <div className={'user-tag product-t ml-2'} key={p?.id}>
                <label className={'label-user-tag'}>{p.name}</label>
                <Icon
                  className={'delete-user-tag d-product-t'}
                  icon={faTimes}
                  tooltip={'Quitar'}
                  color={'white'}
                  onClick={() =>
                    setCategoriesOrder(
                      selectedCategoriesOrder.filter(f => f.id !== p?.id),
                    )
                  }
                />
              </div>
            ))}
            <Button
              style={{ marginLeft: 15 }}
              color={'primary'}
              onClick={() => {
                setShowCategorization({
                  ...showCategorization,
                  show: true,
                  isOrder: true,
                })
              }}>
              Agregar categorías
            </Button>
          </Row>
        </Col>
        {state.paymentType.value === PaymentType.BANK_TRANSFER && (
          <Col xl={12} md={12} lg={12} sm={12} className={'center'}>
            <Gallery
              imageType={imageTypes.DEPOSITS}
              images={state.images}
              onUploadSuccess={(url, name) => {
                setState({
                  ...state,
                  images: [...state.images, { url, original: url, thumbnail: url, name }],
                })
              }}
              onDelete={(image, index) => {
                const { images } = state
                images.splice(index, 1)
                setState({ ...state, images })
              }}
              title={'Agregar foto de depósito'}
              limit={1}
              maxWidth={256}
            />

            <Card
              style={{
                fontSize: 15,
                textAlign: 'center',
                color: '#a21e8c',
                marginTop: 5,
              }}>
              Si se anula el depósito relacionado con la orden de pago, la orden no será
              anulada de manera automática.
            </Card>
          </Col>
        )}
        {amountA && clientOrder?.id !== 0 && (
          <Col xs={12}>
            <br />
            <Switch
              topLabel
              disabled={state.paymentType?.value === 9}
              checked={state.automaticA}
              label={'Aprobar abono automáticamente'}
              onChange={({ target }) => {
                const { checked } = target
                setState({ ...state, automaticA: checked })
              }}
            />
          </Col>
        )}
      </Row>
      <br />
      <Row className={'container-buttons'}>
        <Button
          color={'primary'}
          loading={loading || loadingValR}
          disabled={
            (state.paymentType?.value === 9 &&
              multiple &&
              getMultipleAmount() > getAmount()) ||
            (multiple && valPayment()) ||
            valReceipt ||
            (notCxc && !clientOrder)
          }
          onClick={() => validate()}>
          Realizar pago
        </Button>
      </Row>

      <Folder
        noMessage
        onHide={() =>
          setShowCategorization({
            ...showCategorization,
            show: false,
            filter: false,
            isOrder: false,
          })
        }
        onAssign={item => {
          if (showCategorization.isOrder) {
            let d = selectedCategoriesOrder.find(d => d.id === item.id)
            if (!d) {
              selectedCategoriesOrder.push(item)
              setCategoriesOrder([...selectedCategoriesOrder])
            }
          } else {
            let d = selectedCategories.find(d => d.id === item.id)
            if (!d) {
              selectedCategories.push(item)
              setSelectedCategories([...selectedCategories])
            }
          }
        }}
        data1={
          categorization && categorization.children ? categorization.children[0] : {}
        }
        data2={
          categorization && categorization.children ? categorization.children[1] : {}
        }
        show={showCategorization.show}
        loading={loadingAssigned}
        list={
          showCategorization.isOrder
            ? selectedCategoriesOrder.map(p => p.id)
            : selectedCategories.map(p => p.id)
        }
        type={categoryType.GENERAL}
      />
    </div>
  )
}
export default CreateOrderPayment
