import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Alert from 'sweetalert-react'
import { Modal, Row, Col, ProgressBar } from 'react-bootstrap'
import {
  Paragraph,
  Switch,
  Select,
  CustomDate,
  Card,
  Button,
  Icon,
  CustomTabs,
  TableV2,
} from '../../../components'
import { faPause, faPlay, faSync } from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getAllOrdersLite,
  onConfirmSingleOrderByMassiveAction,
  deleteSingleOrder,
  onCertifySingleOrderByMassiveAction,
} from '../../../actions/orders.actions'
import { isAllowed, selectUsers } from '../../../selectors/modules.selector'
import {
  actionTypes as companyActions,
  getCompanyField,
} from '../../../actions/company.actions'
import { selectCompanyFieldById } from '../../../selectors/company.selector'
import { toMoney } from '../../../utils/utilities'

import { handlerError, handlerInfo, hasErrors } from '../../../selectors/error.selector'
import { loadingSelector } from '../../../selectors/loading.selector'
import { formatDateFromMillis, formatHourFromMillis } from '../../../utils/formatters'
import { orderDispatchType } from 'src/enums/orderDispatchStatus'
import useDisabledDays from 'src/hooks/useDisabledDays'
import { selectResponseMassiveConfirmation } from 'src/selectors/orders.selector'

const OrderMassiveConfirmation = ({
  show,
  selected,
  filters,
  onHide,
  cancel,
  certify,
  all,
}) => {
  const dispatch = useDispatch()

  const facType = useSelector(state => selectCompanyFieldById(state, 90))
  const certificate = useSelector(state => selectCompanyFieldById(state, 94))

  const users = useSelector(selectUsers)

  const dontCertificate = useSelector(state => isAllowed(state, [1123, 2923]))

  // confirm inventory permissions
  //const confirmWithTotalExistence = useSelector(state => isAllowed(state, [1140]))
  const confirmWithParcialExistence = useSelector(state => isAllowed(state, [1141]))
  const confirmWithoutExistence = useSelector(state =>
    isAllowed(state, [1111, 2811, 11661]),
  )

  const automaticFel = useSelector(state => selectCompanyFieldById(state, 117))

  const loading = useSelector(state =>
    loadingSelector([actionTypes.MASSIVE_CONFIRMATION, actionTypes.DELETE_SINGLE_ORDER])(
      state,
    ),
  )
  const error = useSelector(state =>
    hasErrors([actionTypes.MASSIVE_CONFIRMATION, actionTypes.DELETE_SINGLE_ORDER])(state),
  )

  const massiveResponse = useSelector(selectResponseMassiveConfirmation)

  const loadingO = useSelector(state =>
    loadingSelector([actionTypes.GET_ALL_ORDERS_LITE])(state),
  )
  const ordersLite = useSelector(state => state.orders.ordersLite)

  const loadingFELFactType = useSelector(state =>
    loadingSelector([companyActions.GET_FIELD_INVOICE])(state),
  )

  const [flags, setFlags] = useState({
    confirm: false,
    get: false,
    refresh: false,
    felFactType: false,
  })

  const [alert, setAlert] = useState({ title: '' })
  const [open, setOpen] = useState(false)
  const [useFEL, setUseFEL] = useState(true)
  const [config, setConfig] = useState({})
  const { user, certValue, optionDateType, dontUseInventory, partial, date, ignoreCpc } =
    config

  const [orders, setOrders] = useState([]) // Ordenes por Confirmar
  const [modalConfirmation, setModalConfirmation] = useState({
    show: false,
    value: null,
    playStatus: 1,
    orders: [],
  })

  const disabledDays = useDisabledDays(useFEL && certValue)

  const [timer, setTimer] = useState(0)
  const [initialTime, setInitialTime] = useState(0)
  const [finishTime, setFinishTime] = useState(0)

  // Configuración de tiempo
  useEffect(() => {
    setTimeout(() => {
      const today = new Date()
      setTimer(today.valueOf())
    }, 1000)
  }, [timer])

  //  FEL CERTIFICADA
  useEffect(() => {
    if (!useFEL) return

    setConfig({
      certValue: automaticFel.value === 'true' || automaticFel.value === '1',
    })
  }, [automaticFel.value])

  useEffect(() => {
    if (!show) return

    setOpen(!cancel)
    dispatch(getCompanyField(90))
    dispatch(getCompanyField(94))
    dispatch(getCompanyField(117))
    // setUseFEL(facType.value === '1')
    setOrders([])
    setModalConfirmation({ show: cancel, value: null, playStatus: 1 })

    setConfig({
      user: null,
      certValue: automaticFel.value === 'true' || automaticFel.value === '1',
      optionDateType: { value: 1, label: 'Día actual' },
      dontUseInventory: false,
      date: new Date(),
      ignoreCpc: { value: false, label: 'Manejar cuentas por cobrar' },
    })
    if (cancel) getInitialOrders()
  }, [show])

  useEffect(() => {
    if (loadingFELFactType) setFlags({ ...flags, felFactType: true })
    else if (flags.felFactType) {
      setFlags({ ...flags, felFactType: false })
      setUseFEL(facType.value === '1')
      if (cancel) {
        getInitialOrders()
      }
    }
  }, [loadingFELFactType])

  // Se obtienen todas las ordenes en estado 'Ingresada' cuando se seleccionan Todas.
  useEffect(() => {
    if (loadingO) setFlags({ ...flags, get: true })
    else if (flags.get) {
      setFlags({ ...flags, get: false })
      const ol = ordersLite.filter(o => selected.findIndex(s => s.id === o.id) === -1)
      setInitialOrders(ol)
    }
  }, [loadingO])

  // SE itera en las ordenes.
  useEffect(() => {
    if (!(modalConfirmation.show && modalConfirmation.playStatus === 1)) return
    goToNextOrder()
  }, [modalConfirmation.playStatus])

  useEffect(() => {
    if (
      !(
        modalConfirmation.show &&
        modalConfirmation.value &&
        modalConfirmation.playStatus === 1
      )
    )
      return
    const orderIndex = orders.findIndex(o => o.id === modalConfirmation.value)
    if (orderIndex === -1) {
      console.log('wut', modalConfirmation)
    } else {
      let order = orders[orderIndex]
      order.time = new Date()
      orders[orderIndex] = order
      setOrders([...orders])

      if (cancel) {
        dispatch(deleteSingleOrder(order.id, 'Rechazada desde la acción masiva'))
      } else {
        const today = new Date()
        today.setDate(today.getDate() + 30)

        let confirmDate = new Date()

        if (optionDateType?.value !== 1) {
          let customDate = optionDateType?.value === 2 ? date : new Date(order.date)

          if (useFEL && certValue && disabledDays.after && disabledDays.before) {
            const dateMillis = customDate.setHours(0, 0, 0, 0).valueOf()
            const beforeMillis = disabledDays.before.setHours(0, 0, 0, 0).valueOf()
            const afterMillis = disabledDays.after.setHours(0, 0, 0, 0).valueOf()

            if (!(dateMillis >= beforeMillis && dateMillis <= afterMillis))
              customDate = new Date()
          }
          confirmDate = customDate
        }

        const params = {
          user: user?.value,
          paymentDate: today.valueOf(),
          dispatchType: dontUseInventory
            ? orderDispatchType.confirmWithoutInventory
            : partial
            ? orderDispatchType.confirmWithPartialDispatch
            : orderDispatchType.confirmWithFullDispatch,
          dontCertificate: !certValue,
          confirmDate: confirmDate.valueOf(),
          confirmDateType: optionDateType?.value || null,
          ignoreCpc: ignoreCpc.value,
        }
        if (certify) dispatch(onCertifySingleOrderByMassiveAction(order.id, params))
        else dispatch(onConfirmSingleOrderByMassiveAction(order.id, params))
      }
    }
  }, [modalConfirmation.value])

  // Loading cuando se termina de operar una orden
  useEffect(() => {
    if (loading && show) setFlags({ ...flags, confirm: true })
    else if (flags.confirm) {
      setFlags({ ...flags, confirm: false, refresh: true })

      const orderIndex = orders.findIndex(o => o.id === modalConfirmation.value)
      let order = orders[orderIndex]

      let status
      let messageError

      if (error) {
        status = 3
        messageError = error.message
      } else if (massiveResponse?.errors?.length > 0) {
        status = 3
        messageError = massiveResponse.errors[0].message
      } else {
        status = 2
        messageError = undefined
      }

      order.status = status
      order.description = messageError

      order.time = new Date().valueOf() - order.time.valueOf()
      orders[orderIndex] = order
      setOrders([...orders])
      goToNextOrder()
    }
  }, [loading])

  const getInitialOrders = () => {
    if (selected && selected.length > 0 && !all) {
      // Se seleccionan manualmente las ordenes en la tabla
      const newOrders = selected
        .filter(s => (certify ? s.status === 3 && s.invoiced === false : s.status === 1))
        .map(s => ({
          id: s.id,
          code: s.number,
          clientName: s?.client?.name,
          paymentType: s?.paymentTypeData?.name,
          total: s.total,
          status: 1,
          date: s.enabledAt,
          time: 1216004,
        }))
      setTimeout(() => {
        setInitialOrders(newOrders)
      }, 200)
    } else {
      // Se selccionan todas
      const params = {
        ...filters,
        type: 1,
        stateId: certify ? 3 : 1,
        invoiced: all ? false : !certify,
      }
      dispatch(getAllOrdersLite(params)) // Se buscan las ordenes
    }
  }

  const setInitialOrders = items => {
    if (items.length === 0)
      setAlert({
        ...handlerError(
          'No hay ordenes pendientes de confirmar en las ordenes seleccionadas',
        ),
        onConfirm: () => setAlert({ ...alert, show: false }),
      })
    else {
      setOrders(items.map(i => ({ ...i, status: 1 })))
      setModalConfirmation({ show: true, value: null, playStatus: 2 })
    }
  }

  const goToNextOrder = () => {
    const firstOrder = orders.filter(o => o.status === 1)[0]
    let value = []
    let playStatus = modalConfirmation.playStatus
    if (firstOrder) value = firstOrder.id
    else {
      playStatus = 2
      const finished = new Date()
      setFinishTime(finished.valueOf())
      setAlert({
        ...handlerInfo(
          'Proceso concluido',
          `El proceso ha concluido, se realizaron ${
            orders.filter(o => o.status === 2).length
          } ${cancel ? 'rechazos' : 'confirmaciones'} exitosas y en ${
            orders.filter(o => o.status === 3).length
          } se detectaron errores.`,
        ),
        onConfirm: () => setAlert({ ...alert, show: false }),
      })
    }
    setModalConfirmation({ ...modalConfirmation, value, playStatus })
  }

  const onClose = () => {
    setOpen(false)
    setModalConfirmation({ show: false, value: null, playStatus: 1 })
    onHide(flags.refresh)
  }

  const getTime = () => {
    const total = orders.filter(o => o.status !== 1).reduce((a, b) => a + b.time, 0)
    const prom = total / (orders.filter(o => o.status !== 1).length | 1)
    return formatHourFromMillis(prom * orders.filter(o => o.status === 1).length)
  }

  const renderTable = (items, statusType) => {
    return (
      <Row>
        <Col xl={12}>
          <TableV2
            customClass={'scroll-x-without-height'}
            items={items}
            headers={[
              { label: 'Código', show: true, type: ['text'] },
              { label: 'Fecha', show: true, type: ['text'] },
              { label: 'Cliente', show: true, type: ['text'] },
              { label: 'Tipo de pago', show: true, type: ['text'] },
              { label: 'Total', show: true, type: ['text'] },
              { show: true, label: statusType === 3 ? 'Descripción del error' : '' },
            ]}
            renderRow={item => (
              <tr className={'data'} key={item.id}>
                <td className={'mini'}>{item.code}</td>
                <td className={'mini'}>{formatDateFromMillis(item.date)}</td>
                <td className={'medium'}>{item.clientName}</td>
                <td className={'mini'}>{item.paymentType}</td>
                <td className={'mini'}>{toMoney(item.total)}</td>
                <td className={statusType === 3 ? 'large' : 'mini'}>
                  {statusType === 1 && item.id === modalConfirmation.value && loading ? (
                    <Icon icon={faSync} spin tooltip={'En proceso'} size={'2x'} />
                  ) : statusType === 3 ? (
                    <div>{item.description}</div>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            )}
          />
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <Modal
        show={open && !modalConfirmation.show}
        size={'xl'}
        centered
        onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {certify ? 'Certificación' : cancel ? 'Cancelación' : 'Confirmación'} de
            ordenes masiva: Configuración
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col xl={12} className={'mb-2'}>
                <Paragraph>
                  Está acción realizará una{' '}
                  {certify ? 'Certificación' : cancel ? 'Cancelación' : 'Confirmación'}{' '}
                  masiva de órdenes de venta, primero se debe seleccionar la configuración
                  para la operación.
                </Paragraph>
              </Col>

              {!certify && useFEL && (
                <Col xl={4} lg={4} md={6} sm={12}>
                  {certificate && certificate.value === '1' && dontCertificate ? (
                    <div>
                      <Switch
                        topLabel
                        label={'Generar Factura Electrónica'}
                        info={
                          'Si la opción no está activa no se generará un documento legal, únicamente un documento de empresa'
                        }
                        checked={certValue}
                        changeValue={value => {
                          setConfig({
                            ...config,
                            certValue: value,
                            date: value ? new Date() : date,
                          })
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <i>
                        <b>Siempre emitir factura electrónica:</b> Al confirmar la orden
                        de venta, se emitira una factura electrónica.
                      </i>
                    </div>
                  )}
                </Col>
              )}

              {!certify && (
                <Col xl={12}>
                  <Row>
                    {confirmWithoutExistence && (
                      <Col xl={4} lg={4} md={6} sm={12}>
                        <Switch
                          topLabel
                          label={'Confirmar ventas sin inventario'}
                          info={
                            'Si la opción está activa, no se realizara el despacho de inventario en la bodega'
                          }
                          checked={dontUseInventory}
                          changeValue={value =>
                            setConfig({
                              ...config,
                              dontUseInventory: value,
                              partial: !value,
                            })
                          }
                        />
                      </Col>
                    )}

                    {confirmWithParcialExistence && (
                      <Col xl={4} lg={4} md={6} sm={12}>
                        <Switch
                          topLabel
                          label={'Despacho parcial de inventario'}
                          info={
                            '¿Desea realizar un despacho parcial de existencias? Está acción realizara el despacho de las existencias disponibles de los ítems indicados en la órden, las cantidades faltantes quedarán pendientes de despacho y serán accesibles desde la bodega de despacho.'
                          }
                          checked={partial}
                          changeValue={value =>
                            setConfig({
                              ...config,
                              dontUseInventory: !value,
                              partial: value,
                            })
                          }
                        />
                      </Col>
                    )}
                    <Col xl={12} />

                    <Col xl={6} lg={6} md={6} sm={12}>
                      <Select
                        label={'Confirmar bajo el siguiente usuario:'}
                        options={[{ value: null, label: 'sin seleccionar' }, ...users]}
                        value={user}
                        onChange={confirmBy => setConfig({ ...config, user: confirmBy })}
                      />
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={12}>
                      <Select
                        options={[
                          { value: false, label: 'Manejar cuentas por cobrar' },
                          { value: true, label: 'No manejar cuentas por cobrar' },
                        ]}
                        label={
                          'Manejo de cuentas por cobrar para las ventas con tipo de pago: Credito'
                        }
                        value={ignoreCpc}
                        onChange={value => setConfig({ ...config, ignoreCpc: value })}
                        subText={
                          !ignoreCpc?.value
                            ? 'Todas las ventas al crédito quedaran como una cuenta por cobrar'
                            : 'Todas las ventas al crédito no quedaran como una cuenta por cobrar'
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xl={6} lg={6} md={6} sm={12}>
                <Select
                  options={[
                    { value: 1, label: 'Día actual' },
                    { value: 2, label: 'Día personalizado' },
                    { value: 3, label: 'Fecha de creación de la venta' },
                  ]}
                  label={'Opción para fecha de confirmación'}
                  value={optionDateType}
                  onChange={value =>
                    setConfig({ ...config, optionDateType: value, date: new Date() })
                  }
                />
              </Col>

              {useFEL && certValue && optionDateType?.value !== 1 && (
                <Col xl={12}>
                  <Card
                    style={{
                      fontSize: 15,
                      textAlign: 'center',
                      color: '#a21e8c',
                      marginTop: 5,
                    }}>
                    <div>
                      <div>
                        La confirmación de venta con Facturación Electrónica únicamente
                        acepta hasta un máximo de 5 días atrás a la fecha actual y 5 días
                        delante de la fecha actual.
                      </div>

                      <div>
                        Si la orden de venta fue creada hace mas de 5 días desde la fecha
                        actual o la fecha personalizada, se usara la fecha actual como
                        fecha de confirmación
                      </div>
                    </div>
                  </Card>
                </Col>
              )}

              {optionDateType?.value === 2 && (
                <Col xl={12}>
                  <CustomDate
                    label={'Fecha de confirmación'}
                    value={date}
                    disabledDays={disabledDays}
                    onDayChange={value => setConfig({ ...config, date: value })}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingO}
              onClick={() =>
                setAlert({
                  show: true,
                  type: 'warning',
                  title: certify ? 'Certificación masiva' : 'Confirmación masiva',
                  text: `'¿Está seguro de realizar está acción? Las órdenes de venta a ${
                    certify ? 'certificar' : 'confirmar'
                  } tomarán las configuraciones establecidas como parámetros de ${
                    certify ? 'certificación' : 'confirmación'
                  }.`,
                  showCancelButton: true,
                  cancelButtonText: 'Cerrar',
                  confirmButtonText: 'Continuar',
                  confirmButtonColor: '#226095',
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    setAlert({ ...alert, show: false })
                    getInitialOrders()
                  },
                })
              }>
              {certify ? 'Certificar' : 'Confirmar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalConfirmation.show}
        size={'xl'}
        centered
        onHide={() => {
          if (modalConfirmation.playStatus === 2) {
            if (cancel) onClose()
            else {
              setModalConfirmation({ show: false, value: null, playStatus: 1 })
            }
          }
        }}>
        <Modal.Header closeButton={modalConfirmation.playStatus === 2}>
          <Modal.Title>Confirmación de ordenes masiva: En proceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} className={'mb-2'}>
              {!cancel ? (
                <Paragraph>
                  Proceso de confirmación de ventas, las ventas listadas a continuación
                  serán las órdenes de venta que se confirmaran con los parámetros de
                  confirmación especificados anteriormente. Si desea pausar el proceso de
                  confirmación debe de presionar el botón “Pausar”.
                </Paragraph>
              ) : (
                <Paragraph>
                  Proceso de rechazo de ventas, las ventaslstadas a continuación serán las
                  órdenes de venta que se rechazaran. Si desea pausar el proceso de
                  confirmación debe de presionar el botón “Pausar”
                </Paragraph>
              )}
            </Col>
            <Col xl={12}>
              <div className={'d-flex'}>
                <Button
                  color={'primary'}
                  disabled={modalConfirmation.playStatus === 1 && loading}
                  onClick={() => {
                    setModalConfirmation({
                      ...modalConfirmation,
                      playStatus: 1,
                      value: null,
                    })
                    const initial = new Date()
                    setInitialTime(initial.valueOf())
                    setFinishTime(0)
                  }}>
                  <Icon
                    spin={modalConfirmation.playStatus === 1 && loading}
                    icon={faPlay}
                    tooltip={'Iniciar proceso'}
                  />
                </Button>
                <Button
                  color={'accent'}
                  disabled={modalConfirmation.playStatus === 2}
                  onClick={() => {
                    setModalConfirmation({ ...modalConfirmation, playStatus: 2 })
                    const finished = new Date()
                    setFinishTime(finished.valueOf())
                  }}>
                  <Icon icon={faPause} tooltip={'Pausar proceso'} />
                </Button>
                <div className={'column mt-1 ml-1'}>
                  <div className={'b-user-name no-transform'}>
                    Tiempo estimado: {getTime()}
                  </div>
                  <div className={'b-user-name no-transform'}>
                    Tiempo transcurrido:
                    {formatHourFromMillis(
                      initialTime === 0
                        ? 0
                        : (finishTime > 0 ? finishTime : new Date().valueOf()) -
                            initialTime,
                      true,
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xl={12}>
              <div className={'space-between'}>
                <div className={'b-user-name no-transform'}>
                  Inicio: {initialTime === 0 ? '-' : formatDateFromMillis(initialTime)}
                </div>
                <div className={'b-user-name no-transform'}>
                  Fin: {finishTime === 0 ? '-' : formatDateFromMillis(finishTime)}
                </div>
              </div>
            </Col>

            <Col xl={12}>
              <ProgressBar
                now={(orders.filter(o => o.status !== 1).length * 100) / orders.length}
                label={`${orders.filter(o => o.status !== 1).length} / ${orders.length}`}
                animated={loading}
                variant={'success'}
              />
            </Col>

            <Col xl={12}>
              <CustomTabs
                loading={loadingO}
                ctBank
                items={[
                  {
                    title: `Por ${cancel ? 'rechazar' : 'confirmar'} (${
                      orders.filter(o => o.status === 1).length
                    })`,
                    component: renderTable(
                      orders.filter(o => o.status === 1),
                      1,
                    ),
                  },
                  {
                    title: `${cancel ? 'Rechazadas' : 'Confirmadas'} (${
                      orders.filter(o => o.status === 2).length
                    })`,
                    component: renderTable(
                      orders.filter(o => o.status === 2),
                      2,
                    ),
                  },
                  {
                    title: `${cancel ? 'No rechazadas' : 'No confirmadas'} (${
                      orders.filter(o => o.status === 3).length
                    })`,
                    component: renderTable(
                      orders.filter(o => o.status === 3),
                      3,
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default OrderMassiveConfirmation
