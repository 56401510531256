import 'react-tabs/style/react-tabs.css'

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Alert from 'sweetalert-react'

import {
  Title,
  Card,
  SelectedDates,
  Icon,
  Dropdown,
  Checkbox,
  FAB,
  Button,
  FormText,
  Select,
  CustomSummary,
  Operation,
  TableV2,
  Switch,
  NumberField,
  Paragraph,
} from 'src/components'

import {
  faBook,
  faCheckDouble,
  faEdit,
  faExclamationTriangle,
  faFileExcel,
  faLandmark,
  faListUl,
  faMoneyCheck,
  faPercent,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'
import RightArrow from 'src/assets/v2/right-arrow.svg'
import IconFlag from 'src/assets/v2/icon-flag.svg'
import IconDelete from 'src/assets/v2/icon-delete.svg'

import {
  actionTypes as typeO,
  getAllOrders,
  accreditOrder,
  createCardLiquidation,
  getCardLiquidation,
  getOrdersByLiquidation,
  onNullifyCardLiquidation,
  getCardPaymentAmounts,
  cardPaymentReportExcel,
} from 'src/actions/orders.actions'
import {
  selectCardPaymentAmounts,
  selectGetAllOrders,
} from 'src/selectors/orders.selector'

import { actionTypes as typeB, getMovementMatchDeposit } from 'src/actions/banks.actions'
import { selectMovementByDeposit } from 'src/selectors/banks.selector'

import { isAllowed } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerSuccess,
  handlerError,
  handlerInfoWithButtons,
  hasErrors,
} from 'src/selectors/error.selector'

import { formatDateFromMillis, formatNumberWithCommas } from 'src/utils/formatters'

import { onSetModalOrder } from 'src/actions/utilities.actions'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import { toMoney } from 'src/utils/utilities'
import Money from 'src/components/Money/Money'
import { showAlert } from 'src/actions/alert.actions'
import { capitalizeWorld, haveAnyValue } from 'src/utils/utilitiesV2'
import { liquidationStatusEnum, orderCardLiquidationStatusEnum } from 'src/enums/order'
import { selectAllPOSUser } from '../../selectors/restaurant.selector'
import { getAllPOSByUser } from '../../actions/restaurant.actions'
import { selectCurrentUser } from '../../selectors/user.selector'

const initialLiquidationValue = {
  show: false,
  orders: [],
  total: 0,
  commission: 0,
  fiscal: 0,
  retention: 0,
}

const today = new Date()

const CardCharges = () => {
  const dispatch = useDispatch()

  const company = useSelector(selectSetCurrentCompany)

  const loadingOrders = useSelector(state =>
    loadingSelector([typeO.GET_ALL_ORDERS])(state),
  )
  const orders = useSelector(selectGetAllOrders)

  const loadingCardLiquidation = useSelector(state =>
    loadingSelector([typeO.GET_CARD_LIQUIDATION])(state),
  )
  const cardsLiquidation = useSelector(state => state.orders.cardsLiquidation)

  const loadingOrdersLiquidated = useSelector(state =>
    loadingSelector([typeO.GET_ORDERS_LIQUIDATED])(state),
  )
  const ordersLiquidated = useSelector(state => state.orders.ordersLiquidated)
  const cardPaymentAmounts = useSelector(selectCardPaymentAmounts)

  const loadingCreateCardLiquidation = useSelector(state =>
    loadingSelector([typeO.CREATE_CARD_LIQUIDATION])(state),
  )
  const hasErrorCardLiquidation = useSelector(state =>
    hasErrorsSelector([typeO.CREATE_CARD_LIQUIDATION])(state),
  )
  const errorCardLiquidation = useSelector(state =>
    singleErrorSelector([typeO.CREATE_CARD_LIQUIDATION])(state),
  )

  const posUser = useSelector(selectAllPOSUser)
  const user = useSelector(selectCurrentUser)
  const movements = useSelector(selectMovementByDeposit)
  const loadingMovementByDeposit = useSelector(state =>
    loadingSelector([typeB.GET_MOVEMENT_BY_DEPOSIT])(state),
  )

  const loadingBankAccreditation = useSelector(state =>
    loadingSelector([typeO.ON_ACCREDIT_WITH_BANK])(state),
  )
  const hasErrorBankAccreditation = useSelector(state =>
    hasErrorsSelector([typeO.ON_ACCREDIT_WITH_BANK])(state),
  )
  const errorBankAccreditation = useSelector(state =>
    singleErrorSelector([typeO.ON_ACCREDIT_WITH_BANK])(state),
  )

  const loadingNullifyLiquidation = useSelector(state =>
    loadingSelector([typeO.NULLIFY_CARD_LIQUIDATION])(state),
  )
  const errorNullifyLiquidation = useSelector(state =>
    hasErrors([typeO.NULLIFY_CARD_LIQUIDATION])(state),
  )
  const loadingCardPaymentExcel = useSelector(state =>
    loadingSelector([typeO.CARD_PAYMENT_EXCEL])(state),
  )

  //permissions
  const canUseCard = useSelector(state => isAllowed(state, [16201, 16251]))
  const canUseBank = useSelector(state => isAllowed(state, [16202, 16252]))
  const canSeeMoneyBalances = useSelector(state => isAllowed(state, [16203, 16253]))

  const [actions, setActions] = useState({ card: false, bank: false, nullify: false })
  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: today,
    creditStatus: 1,
    search: null,
    payment: { value: 6, label: 'Cobro con tarjeta' },
    orders: true,
    pos: null,
  })
  const payments = [
    { value: 6, label: 'Cobro con tarjeta' },
    { value: 3, label: 'Cobros desde link de pago kolo' },
  ]
  const [companySelected, setCompany] = useState({ value: null, label: 'Todos' })

  const [selected, setSelected] = useState([])
  const [creditModal, setCreditModal] = useState(initialLiquidationValue)
  const [bankModal, setBankModal] = useState({ show: false, movement: null, total: 0 })
  const [alert, setAlert] = useState({ title: '' })
  const [checked, setChecked] = useState(false)
  const [seeOrders, setSeeOrders] = useState({ show: false })
  const [percentCommission, setPercentCommission] = useState(false)
  const [percentIva, setPercentIva] = useState(false)
  const [cardLiquidationId, setCardLiquidation] = useState(null)

  useEffect(() => {
    setTextFilter(JSON.stringify(filters))
    dispatch(getAllPOSByUser(user.id))
  }, [])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp()
  }, [textFilter])

  useEffect(() => {
    if (loadingCreateCardLiquidation) setActions({ ...actions, card: true })
    else if (actions.card) {
      setActions({ ...actions, card: false })
      if (hasErrorCardLiquidation)
        setAlert({
          ...handlerError(errorCardLiquidation.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess(
            'Los cobros de tarjeta de crédito fueron registrados satisfactoriamente',
          ),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setSelected([])
            setChecked(false)
            setCreditModal(initialLiquidationValue)
            setUp()
          },
        })
    }
  }, [loadingCreateCardLiquidation])

  useEffect(() => {
    if (loadingBankAccreditation) setActions({ ...actions, bank: true })
    else if (actions.bank) {
      setActions({ ...actions, bank: false })
      if (hasErrorBankAccreditation)
        setAlert({
          ...handlerError(errorBankAccreditation.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Acreditación bancaria satisfactoria'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setBankModal({ ...bankModal, show: false })
            setSelected([])
            setChecked(false)
            setUp()
          },
        })
    }
  }, [loadingBankAccreditation])

  useEffect(() => {
    if (loadingNullifyLiquidation) setActions({ ...actions, nullify: true })
    else if (actions.nullify) {
      setActions({ ...actions, nullify: false })
      setCardLiquidation(null)
      const alert = errorNullifyLiquidation
        ? handlerError(errorNullifyLiquidation.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingNullifyLiquidation])

  const setUp = () => {
    const { start, end, search, creditStatus, payment, orders, status, pos } = filters
    setChecked(false)

    dispatch(
      getCardPaymentAmounts({
        start: start.valueOf(),
        end: end.valueOf(),
      }),
    )

    if (orders)
      dispatch(
        getAllOrders({
          start: start.valueOf(),
          end: end.valueOf(),
          payment: payment.value,
          type: null,
          skip: 0,
          size: 10000,
          search,
          linkType: payment.value === 6 ? 2 : null,
          creditStatus,
          pos: pos?.id,
        }),
      )
    else
      dispatch(
        getCardLiquidation({
          start: start.valueOf(),
          end: end.valueOf(),
          status,
        }),
      )
  }

  const getExcel = () => {
    const { start, end, search, payment, pos } = filters

    dispatch(
      cardPaymentReportExcel({
        start: start.valueOf(),
        end: end.valueOf(),
        payment: payment.value,
        type: null,
        skip: 0,
        size: 10000,
        search,
        linkType: payment.value === 6 ? 2 : null,
        creditStatus: 1,
        pos: pos?.id,
      }),
    )
  }

  const isOrderSelected = orderId => selected.some(s => s === orderId)

  const setOrder = order => {
    const newSelected = isOrderSelected(order.id)
      ? selected.filter(s => s !== order.id)
      : [...selected, order.id]
    setSelected(newSelected)

    setChecked(newSelected.length === orders.length)
  }

  const companies = [
    { value: null, label: 'Todos' },
    ...orders
      .filter(({ companyData }) => companyData)
      .filter(({ company }, _, companies) => companies.every(co => co.id !== company))
      .map(({ companyData, company }) => ({
        ...companyData,
        value: company,
        label: companyData.name,
      })),
  ]

  const items = orders.filter(
    o => !companySelected.value || o.company === companySelected.value,
  )

  const getTable = (seeValues, ol) => {
    const headers = []
    if (filters.creditStatus === 1 && !ol) {
      headers.push({
        label: '',
        show: true,
        select: true,
        checked,
        custom: item => <Checkbox checked={isOrderSelected(item.id)} />,
        className: 'mini',
      })
    }
    headers.push(
      ...[
        {
          label: 'Tipo',
          show: true,
          value: ['tipoC'],
          type: 'text',
          className: 'mini',
          custom: item => (item.tipoC = item.type === 1 ? 'Venta' : 'Pago'),
        },
        {
          label: 'Número',
          show: true,
          value: ['number'],
          type: 'text',
          className: 'mini',
        },
        {
          label: 'Cliente',
          show: true,
          value: ['client', 'name'],
          type: 'text',
          className: 'short',
          custom: item => (item.client ? item.client.name : 'Desconocido'),
        },
        {
          label: 'Fecha',
          show: true,
          value: ['fechaC'],
          type: 'text',
          className: 'mini',
          custom: item =>
            (item.fechaC = formatDateFromMillis(item.enabledAt || item.createdAt)),
        },
        {
          label: 'Encargado',
          show: true,
          value: ['deliverer', 'name'],
          type: 'text',
          className: 'short',
        },
        {
          label: 'Entregado por',
          show: true,
          value: ['responsible', 'name'],
          type: 'text',
          className: 'short',
        },
        {
          label: 'Total',
          show: canSeeMoneyBalances,
          value: ['total'],
          type: 'currency',
          className: 'mini',
          select: !canSeeMoneyBalances,
        },
      ],
    )
    const withSurcharge = filters.payment.value === 3

    if (withSurcharge) {
      headers.push({
        label: 'Recargo',
        show: canSeeMoneyBalances,
        value: ['surcharge'],
        type: 'currency',
        className: 'mini',
      })
    }

    if (seeValues) {
      headers.push(
        ...[
          {
            label: 'Comisión',
            show: true,
            value: ['commission'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            label: 'Crédito fiscal (IVA)',
            show: true,
            value: ['fiscalCredit'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            label: 'Retención',
            show: true,
            value: ['retention'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
        ],
      )
    }
    headers.push({
      config: true,
      show: true,
      label: '',
      className: 'mini center',
      custom: item =>
        filters.creditStatus === 1 && !ol ? (
          <Dropdown
            items={[
              { title: 'Ver orden', action: () => dispatch(onSetModalOrder(item.id)) },
              {
                show: filters.creditStatus === 1 && canUseCard,
                title: 'Asignar cobro de tarjeta de crédito',
                action: () => onCreditValues([item]),
              },
              {
                show: filters.creditStatus === 2 && canUseBank,
                title: 'Acreditar con movimiento bancario',
                action: () => {
                  onBankValues([item])
                },
              },
            ]}
          />
        ) : (
          <Icon
            size={'2x'}
            icon={faBook}
            tooltip={'Ver orden'}
            onClick={() => dispatch(onSetModalOrder(item.id))}
          />
        ),
    })

    return (
      <TableV2
        headers={headers}
        loading={loadingOrders || loadingOrdersLiquidated}
        items={ol || items}
        placeholder={'Buscar por Cliente/Bodega/Producto/No. Orden'}
        noItemsLegend={'No se encontraron órdenes con los filtros aplicados.'}
        mobileAuto
        storageKey={'cardChanges'}
        onCheck={
          filters.creditStatus === 1 && !ol
            ? item => {
                setChecked(item)
                setSelected(orders.map(order => order.id))
              }
            : null
        }
        onClickTr={(e, item) => {
          if (filters.creditStatus === 1 && !ol) setOrder(item)
        }}
        getItems={selected => {
          if (selected === null) {
            setChecked(false)
            setSeeOrders([])
          } else if (filters.creditStatus === 1 && !ol) {
            setOrder(selected)
          }
        }}
        balancePermission={canSeeMoneyBalances}
      />
    )
  }

  const lTable = seeStatus => {
    return (
      <TableV2
        headers={[
          {
            label: 'Número',
            show: true,
            value: ['number'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Estado',
            show: seeStatus,
            value: [],
            type: 'text',
            className: 'mini',
            custom: item => (
              <div className={'column center'}>
                {item.status === orderCardLiquidationStatusEnum.WARNING && (
                  <Icon
                    className={'mb-1'}
                    icon={faExclamationTriangle}
                    color={'orange'}
                    size={'1x'}
                    tooltip={'Esta transacción necesita revisión'}
                  />
                )}
                <Paragraph>{liquidationStatusEnum[item.status]}</Paragraph>
              </div>
            ),
          },
          {
            label: 'Fecha',
            show: true,
            value: ['createdAt'],
            type: 'date',
            className: 'mini',
          },
          {
            label: 'Ingresado por',
            show: true,
            value: ['createdData', 'name'],
            type: 'text',
            className: 'medium',
          },
          {
            label: 'Descripción',
            show: true,
            value: ['description'],
            type: 'text',
            className: 'medium',
          },
          {
            label: 'Comisión',
            show: true,
            value: ['commission'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            label: 'Crédito fiscal (IVA)',
            show: true,
            value: ['fiscalCredit'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            label: 'Retención',
            show: true,
            value: ['retention'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            label: 'Balance',
            show: true,
            value: ['amount'],
            type: 'currency',
            className: 'mini',
            select: !canSeeMoneyBalances,
          },
          {
            config: true,
            show: true,
            label: '',
            className: 'mini center',
            custom: item => {
              const seeLiquidationActions = [
                orderCardLiquidationStatusEnum.PENDING,
                orderCardLiquidationStatusEnum.COMPLETED,
                orderCardLiquidationStatusEnum.WARNING,
              ].some(status => status === item.status)

              const canUpdateLiquidation = [
                orderCardLiquidationStatusEnum.PENDING,
                orderCardLiquidationStatusEnum.WARNING,
              ].some(status => status === item.status)

              return (
                <Dropdown
                  disabled={loadingNullifyLiquidation}
                  loading={loadingNullifyLiquidation && item.id === cardLiquidationId}
                  items={[
                    {
                      show: canUpdateLiquidation,
                      title: 'Editar',
                      icon: faEdit,
                      action: () => {
                        setPercentIva(false)
                        setPercentCommission(false)
                        setCreditModal({
                          show: true,
                          orders: [],
                          total: item.amount,
                          commission: item.commission,
                          fiscal: item.fiscalCredit,
                          retention: item.retention,
                          description: item.description,
                          id: item.id,
                        })
                      },
                    },
                    {
                      show:
                        item.status === orderCardLiquidationStatusEnum.PENDING &&
                        canUseBank,
                      title: 'Acreditar con movimiento bancario',
                      icon: faCheckDouble,
                      action: () => {
                        dispatch(getMovementMatchDeposit(1, 5, null, item.amount))
                        setBankModal({
                          show: true,
                          movement: null,
                          total: item.amount,
                          orders: [],
                          liquidation: item.id,
                        })
                      },
                    },
                    {
                      show: seeLiquidationActions,
                      title: 'Anular liquidación',
                      icon: faExclamationTriangle,
                      action: () =>
                        dispatch(
                          showAlert({
                            ...handlerInfoWithButtons(
                              'Anular liquidación',
                              'Todas las ordenes de venta asociados a la liquidación serán liberadas y quedarán disponibles para una nueva liquidación.',
                            ),
                            onConfirm: () => {
                              setCardLiquidation(item.id)
                              dispatch(onNullifyCardLiquidation(item.id))
                            },
                          }),
                        ),
                    },
                    {
                      show: seeLiquidationActions,
                      title: 'Ver ordenes asociadas',
                      icon: faListUl,
                      action: () => {
                        dispatch(getOrdersByLiquidation(item.id))
                        setSeeOrders({ show: true })
                      },
                    },
                  ]}
                />
              )
            },
          },
        ]}
        loading={loadingCardLiquidation}
        items={cardsLiquidation}
        mobileAuto
        storageKey={`acreditPending`}
        balancePermission={canSeeMoneyBalances}
      />
    )
  }

  const statusTabs = [
    {
      label: 'A registrar cobros',
      image: RightArrow,
      seeValues: false,
      show: true,
      creditStatus: 1,
    },
    {
      label: 'Pendientes de acreditar',
      image: RightArrow,
      seeValues: true,
      show: true,
      status: `${orderCardLiquidationStatusEnum.PENDING},${orderCardLiquidationStatusEnum.WARNING}`,
      creditStatus: 2,
    },
    {
      label: 'Completadas',
      image: IconFlag,
      seeValues: true,
      show: true,
      status: `${orderCardLiquidationStatusEnum.COMPLETED}`,
      creditStatus: 3,
    },
    { label: 'Link de pago', image: IconFlag, seeValues: false, show: company.id === 1 },
    {
      label: 'Anuladas',
      image: IconDelete,
      seeValues: true,
      show: true,
      status: `${orderCardLiquidationStatusEnum.NULLIFIED}`,
      creditStatus: 4,
    },
  ]

  const tabsToRender = statusTabs.filter(statusTab => statusTab.show)

  const getTotal = orders => {
    let total = 0
    orders.map(o => (total += Number.parseFloat(o.paymentBalance)))
    return Number.parseFloat(total.toFixed(2))
  }

  const onCreditValues = orders => {
    const total = getTotal(orders)
    setCreditModal({
      show: true,
      orders,
      total,
      commission: 0,
      fiscal: 0,
      retention: 0,
    })
  }

  const onBankValues = orders => {
    const total = getTotal(orders)
    dispatch(getMovementMatchDeposit(1, 5, null, total))
    setBankModal({ show: true, movement: null, total, orders })
  }

  const getAmount = param => {
    return items.reduce((a, item) => a + (item[param] || 0), 0)
  }

  const onChangeLiquidationValues = (value, param, total, isPercent) => {
    if (!value || value <= 0) value = 0
    let amount
    let percentAmount
    if (isPercent) {
      if (value > 100) value = 100
      amount = Number(((value / 100) * total).toFixed(2))
      percentAmount = value
    } else {
      amount = value > total ? total : value
      percentAmount = 0
    }
    setCreditModal({
      ...creditModal,
      [param]: amount,
      [`percent${capitalizeWorld(param)}`]: percentAmount,
    })
  }
  const getButtons = () => {
    return (
      <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
        <Icon
          style={{ marginRight: '15px' }}
          icon={faFileExcel}
          spin={loadingCardPaymentExcel}
          tooltip={'Descargar reporte'}
          onClick={() => {
            getExcel()
          }}
        />
      </Row>
    )
  }
  return (
    <div>
      <Title title={'Cobros con tarjeta'} />

      <Card title={'Listado'} white button={getButtons()}>
        <div className={'mt-1'}>
          <CustomSummary
            items={[
              {
                title: 'Total',
                value: toMoney(getAmount('total') + (cardPaymentAmounts.total || 0)),
                show: canSeeMoneyBalances,
              },
              {
                title: 'En proceso',
                value: toMoney(getAmount('total')),
                show: canSeeMoneyBalances,
              },
              {
                title: 'Recargo',
                value: toMoney(getAmount('surcharge')),
                show: company.id === 1 && canSeeMoneyBalances,
              },
              {
                title: 'Pendiente de acreditar',
                value: toMoney(cardPaymentAmounts.toProcess),
                show: canSeeMoneyBalances,
              },
              {
                title: 'Acreditado',
                value: toMoney(cardPaymentAmounts.accredited),
                show: canSeeMoneyBalances,
              },
              {
                title: 'Comisión e impuestos',
                value: toMoney(cardPaymentAmounts.commission),
                show: canSeeMoneyBalances,
              },
            ]}
          />

          <div>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <SelectedDates
                  nonExecute
                  dates={{
                    dateFrom: filters.start,
                    dateTo: filters.end,
                  }}
                  onDateChange={(start, end) => setFilters({ ...filters, start, end })}
                />
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <Select
                  label={'Por punto de venta'}
                  value={filters.pos}
                  info={'Filtra las órdenes por el punto de venta'}
                  options={[{ value: null, label: '- Todos -' }, ...posUser]}
                  onChange={value => {
                    setFilters({ ...filters, pos: value })
                  }}
                />
              </Col>
              {company.id === 1 && (
                <>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <Select
                      label={'Filtrar por tipo de pago'}
                      options={payments}
                      value={filters.payment}
                      onChange={payment => setFilters({ ...filters, payment })}
                    />
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <Select
                      label={'Filtrar por empresa'}
                      options={companies}
                      value={companySelected}
                      onChange={c => setCompany(c)}
                    />
                  </Col>
                </>
              )}
            </Row>
            <br />
          </div>

          <Tabs
            defaultIndex={0}
            onSelect={index => {
              const tab = tabsToRender[index]

              const orders = !haveAnyValue(tab.status)

              setSelected([])
              setFilters({
                ...filters,
                creditStatus: tab.creditStatus,
                orders: orders,
                status: tab.status,
              })
            }}>
            <TabList>
              {tabsToRender.map((item, index) => (
                <Tab key={index}>
                  <div style={{ display: 'flex' }}>
                    <div>{item.label}</div>
                    <img src={item.image} alt="" className="tabs-img" />
                  </div>
                </Tab>
              ))}
            </TabList>
            {tabsToRender.map((item, index) => (
              <TabPanel key={index}>
                {!haveAnyValue(item.status)
                  ? getTable(item.seeValues)
                  : lTable(item, index === 1)}
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </Card>

      <Modal show={creditModal.show} centered onHide={() => undefined}>
        <Modal.Header>
          <Modal.Title>Asignación de cobros de tarjeta de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <FormText
                value={creditModal.total}
                label={`(${creditModal.orders.length}) Total de ventas`}
                disabled
                prependMoneySymbol
              />
            </Col>

            <Col xl={12} md={12} sm={12}>
              <FormText
                label={'Descripción'}
                value={creditModal.description}
                as={'textarea'}
                max={350}
                changeValue={value =>
                  setCreditModal({ ...creditModal, description: value })
                }
              />
            </Col>

            <Col xl={12} md={12} sm={12}>
              <Row>
                <Col xl={10} md={10} sm={10}>
                  <NumberField
                    value={
                      percentCommission
                        ? creditModal.percentCommission
                        : creditModal.commission
                    }
                    label={`Comisión`}
                    type={'number'}
                    isMoney
                    decimals={2}
                    onValueChange={value =>
                      onChangeLiquidationValues(
                        value,
                        'commission',
                        creditModal.total,
                        percentCommission,
                      )
                    }
                  />
                  {percentCommission && (
                    <div className="left">
                      <label>{toMoney(creditModal.commission || 0)}</label>
                    </div>
                  )}
                </Col>
                <Col xl={2} md={2} sm={2} style={{ marginTop: '30px' }}>
                  <Switch
                    checked={percentCommission}
                    label={'%'}
                    topLabel
                    onChange={({ target }) => {
                      const { checked } = target
                      setPercentCommission(checked)
                      onChangeLiquidationValues(0, 'commission', 0, false)
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={12} md={12} sm={12}>
              <Row>
                <Col xl={10} md={10} sm={10}>
                  <NumberField
                    value={percentIva ? creditModal.percentFiscal : creditModal.fiscal}
                    label={`IVA de la comisión:`}
                    type={'number'}
                    prependMoneySymbol={!percentIva}
                    prepend={<Icon icon={faPercent} tooltip={'Cantidad'} />}
                    decimals={2}
                    onValueChange={value =>
                      onChangeLiquidationValues(
                        value,
                        'fiscal',
                        creditModal.commission,
                        percentIva,
                      )
                    }
                  />

                  {percentIva && (
                    <div className="left">
                      <label> {toMoney(creditModal.fiscal || 0)}</label>
                    </div>
                  )}
                </Col>
                <Col xl={2} md={2} sm={2} style={{ marginTop: '30px' }}>
                  <Switch
                    checked={percentIva}
                    label={'%'}
                    topLabel
                    onChange={({ target }) => {
                      const { checked } = target
                      setPercentIva(checked)

                      onChangeLiquidationValues(0, 'fiscal', 0, false)
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={12} md={12} sm={12}>
              <NumberField
                value={creditModal.retention}
                label={`Retención IVA`}
                type={'number'}
                prependMoneySymbol
                decimals={2}
                onValueChange={value => {
                  const available =
                    creditModal.total -
                    (creditModal.commission || 0) -
                    (creditModal.fiscal || 0)

                  onChangeLiquidationValues(value, 'retention', available, false)
                }}
              />
            </Col>

            <Col xl={12} md={12} sm={12}>
              <Row className={'container-buttons'}>
                <div className="invoice-total" style={{ maxWidth: 3000 }}>
                  <div className="invoice-total-title">Neto a pagar:</div>
                  <Money className="invoice-total-text">
                    {creditModal.total -
                      creditModal.commission -
                      creditModal.fiscal -
                      creditModal.retention}
                  </Money>
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              disabled={loadingCreateCardLiquidation}
              color={'secondary'}
              icon={faWindowClose}
              left
              onClick={() => {
                setCreditModal(initialLiquidationValue)
                setPercentCommission(false)
                setPercentIva(false)
              }}>
              Cerrar
            </Button>
            <Button
              loading={loadingCreateCardLiquidation}
              color={'primary'}
              icon={faSave}
              left
              onClick={() => {
                const request = {
                  description: creditModal.description,
                  commission: creditModal.commission,
                  fiscalCredit: creditModal.fiscal,
                  retention: creditModal.retention,
                  amount:
                    creditModal.total -
                    creditModal.commission -
                    creditModal.fiscal -
                    creditModal.retention,
                  orders: creditModal?.orders?.map(o => o.id),
                  id: creditModal.id,
                }
                dispatch(createCardLiquidation(request))
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={bankModal.show}
        centered
        size={'lg'}
        onHide={() => setBankModal({ ...bankModal, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Acreditación bancaria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} lg={12}>
              <b>
                <p>
                  Para completar la liquidación seleccionada es necesario seleccionar un
                  movimiento bancario igual al monto del balance de la liquidación.
                </p>
              </b>
            </Col>
            <Col xl={4} md={6} sm={12}>
              <FormText
                label={'Saldo disponible para acreditación'}
                disabled
                onChange={() => undefined}
                value={toMoney(
                  bankModal.total - (bankModal.movement ? bankModal.movement.amount : 0),
                )}
              />
            </Col>
            <Col xl={8} md={6} sm={12} className={'mt-5'}>
              <Operation
                card
                button
                onHide={refresh => {
                  if (refresh)
                    dispatch(getMovementMatchDeposit(1, 5, null, bankModal.total))
                }}
              />
            </Col>
            <Col xl={12} md={12} sm={12}>
              <TableV2
                items={movements}
                loading={loadingMovementByDeposit}
                noItemsLegend={
                  'No se encontraron movimientos bancarios que coinciden con el monto'
                }
                headers={[
                  {
                    label: '',
                    show: true,
                    select: true,
                    custom: item => (
                      <Checkbox
                        disabled={bankModal.movement && item.id !== bankModal.movement.id}
                        checked={bankModal.movement && item.id === bankModal.movement.id}
                      />
                    ),
                    className: 'mini',
                    simpleChecked: true,
                  },
                  {
                    label: 'Fecha',
                    show: true,
                    value: ['createdAt'],
                    type: 'date',
                    className: 'mini',
                  },
                  {
                    label: 'Referencia',
                    show: true,
                    value: ['reference'],
                    type: 'text',
                    className: 'mini',
                  },
                  {
                    label: 'Cantidad',
                    show: true,
                    value: ['amount'],
                    type: 'currency',
                    className: 'mini',
                  },
                  {
                    label: 'Descripción',
                    show: true,
                    value: ['description'],
                    type: 'text',
                    className: 'medium',
                  },
                  { config: true, show: true, label: '', className: 'mini center' },
                ]}
                onClickTr={(e, item) => {
                  if (bankModal.movement && item.id === bankModal.movement.id)
                    setBankModal({ ...bankModal, movement: null })
                  else setBankModal({ ...bankModal, movement: item })
                }}
                mobileAuto
                storageKey={`bankModal`}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingBankAccreditation}
              disabled={!bankModal.movement}
              color={'primary'}
              icon={faSave}
              left
              onClick={() => {
                dispatch(accreditOrder(bankModal.movement.id, bankModal.liquidation))
              }}>
              Acreditar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={seeOrders.show}
        size={'lg'}
        centered
        onHide={() => setSeeOrders({ show: false })}>
        <Modal.Header>
          <Modal.Title>Ordenes liquidadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getTable(false, ordersLiquidated, true)}</Modal.Body>
      </Modal>

      <Alert {...alert} />

      <FAB
        show={
          selected.length > 0 &&
          filters.creditStatus !== 3 &&
          ((filters.creditStatus === 1 && canUseCard) ||
            (filters.creditStatus === 2 && canUseBank))
        }
        icon={filters.creditStatus === 1 ? faMoneyCheck : faLandmark}
        dontToggle
        onClick={() => {
          if (filters.creditStatus === 1) {
            onCreditValues(orders.filter(o => isOrderSelected(o.id)))
          } else {
            onBankValues(orders.filter(o => isOrderSelected(o.id)))
          }
        }}
      />
    </div>
  )
}
export default CardCharges
