import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { haveAnyValue } from 'src/utils/utilitiesV2'
import { PublicLayout } from 'src/content/PublicDocument/PublicLayout'

import { actions, getLinkPayment } from 'src/actions/cardProvider.actions'
import { selectCardVoucherResponse } from 'src/selectors/cardProvider.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { hasErrors } from 'src/selectors/error.selector'

import { Card, Paragraph } from 'src/components'
import { Col, Row } from 'react-bootstrap'
import { getPaymentData } from 'src/content/CardProviders/CardPaymentFunctions'
import { CardVoucher } from 'src/content/CardProviders/CardVoucher'

export const PublicCardCallback = () => {
  const dispatch = useDispatch()
  const { uuid, order } = useParams() as { uuid: string; order: string }

  const response = useSelector(selectCardVoucherResponse)
  const loading = useSelector(state =>
    loadingSelector([actions.GET_LINK_PAYMENT_VALUES])(state),
  )
  const hasError = useSelector(state =>
    hasErrors([actions.GET_LINK_PAYMENT_VALUES])(state),
  )

  const [flag, setFlag] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const [titles, setTitles] = useState({
    title: 'Procesando tu pago…',
    subtitle:
      'Por favor, espera un momento mientras confirmamos los detalles de tu transacción.',
  })

  const [data, setData] = useState<ISelectString[]>([])

  useEffect(() => {
    if (haveAnyValue(uuid) && haveAnyValue(order)) {
      dispatch(getLinkPayment(order, uuid))
    } else {
      handlerError(
        'Por favor, verifique que el código de autorización esté correctamente escrito.\n Es posible que la ruta sea incorrecta.',
      )
    }
  }, [])

  const handlerError = (message: string) => {
    setError(message)
    setTitles({ title: '', subtitle: '' })
  }

  useEffect(() => {
    if (loading) setFlag(true)
    else if (flag) {
      setFlag(false)

      if (hasError || response.status !== 'PAGO ACEPTADO') {
        const messageError =
          response.message || hasError.message || 'Ha ocurrido un error inesperado.'
        handlerError(messageError)
      } else {
        const values: ISelectString[] = getPaymentData(response)

        setData(values)
        setTitles({
          title: '¡Pago realizado con éxito!',
          subtitle:
            'Gracias por tu compra. A continuación encontrarás los detalles de tu transacción.',
        })
      }
    }
  }, [loading])

  return (
    <PublicLayout
      title={titles.title}
      description={titles.subtitle}
      loading={loading}
      isEmpty={haveAnyValue(error)}
      empty={{
        title: '¡Ups! Ocurrió un problema con tu pago',
        subtitle: error,
      }}>
      <CardVoucher date={response?.date} data={data} />
    </PublicLayout>
  )
}
