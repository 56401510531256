import React, { ReactNode } from 'react'
import { Card, Empty, Paragraph, Loading } from 'src/components'
import { List } from 'react-content-loader'

export interface EmptyProps {
  title: string
  subtitle: string
}

interface PublicLayoutProps {
  children: ReactNode
  title: string
  description: string
  loading: boolean
  isEmpty: boolean
  empty: EmptyProps
}

export const PublicLayout = ({
  title,
  description,
  children,
  loading,
  isEmpty,
  empty,
}: PublicLayoutProps) => {
  return (
    <div className={'justify-content-center mt-5 column p-4'}>
      <Paragraph size={'title'} className={'center'}>
        {title}
      </Paragraph>
      <Paragraph size={'big'} bold className={'center mb-3 '}>
        {description}
      </Paragraph>

      {loading ? (
        <Loading show />
      ) : isEmpty ? (
        <Card>
          <Empty hideButton subtitle={empty.title} subtitleSecond={empty.subtitle} />
        </Card>
      ) : (
        children
      )}
    </div>
  )
}
