import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Title } from 'src/components'
import {
  actions,
  getProvider,
  getProviders,
  onCreateConnection,
  onDeleteConnection,
} from 'src/actions/cardProvider.actions'
import { CardProviderList } from 'src/content/CardProviders/CardProviderList'
import { Col, Row } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CardProviderForm } from 'src/content/CardProviders/CardProviderForm'
import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'
import { isAllowed } from 'src/selectors/modules.selector'
import { cardProviderPermissions } from 'src/enums/permissions'
import { selectCardProvider } from 'src/selectors/cardProvider.selector'
import { CardProviderOptions } from 'src/enums/CardProviderEnum'

interface ShowProviderForm {
  id?: number
  provider?: ICardProviderDTO
  show: boolean
}

interface ICardProviderFlags {
  create: boolean
  delete: boolean
  detail: boolean
}

export const CardProviderPage = () => {
  const dispatch = useDispatch()

  const loadingCreate = useSelector(state =>
    loadingSelector([actions.CREATE_CARD_CONNECTION])(state),
  )
  const errorCreate = useSelector(state =>
    hasErrors([actions.CREATE_CARD_CONNECTION])(state),
  )

  const loadingDelete = useSelector(state =>
    loadingSelector([actions.DELETE_CARD_CONNECTION])(state),
  )
  const errorDelete = useSelector(state =>
    hasErrors([actions.DELETE_CARD_CONNECTION])(state),
  )

  const detailResponse = useSelector(selectCardProvider)

  const loadingDetail = useSelector(state =>
    loadingSelector([actions.GET_CARD_PROVIDER])(state),
  )
  const errorDetail = useSelector(state => hasErrors([actions.GET_CARD_PROVIDER])(state))

  const canCreate = useSelector(state =>
    isAllowed(state, [cardProviderPermissions.create]),
  )

  const [flags, setFlags] = useState<ICardProviderFlags>({
    create: false,
    delete: false,
    detail: false,
  })

  const [showProvider, setShowProvider] = useState<ShowProviderForm>({
    show: false,
  })

  useEffect(() => {
    setUp()
  }, [])

  useEffect(() => {
    if (loadingCreate) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: true })
      const alert = errorCreate
        ? handlerError(errorCreate.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingCreate])

  useEffect(() => {
    if (loadingDelete) setFlags({ ...flags, delete: true })
    else if (flags.delete) {
      setFlags({ ...flags, delete: true })
      const alert = errorDelete
        ? handlerError(errorDelete.message)
        : {
            ...handlerSuccess(),
            onConfirm: () => setUp(),
          }
      dispatch(showAlert(alert))
    }
  }, [loadingDelete])

  useEffect(() => {
    if (loadingDetail) setFlags({ ...flags, detail: true })
    else if (flags.detail) {
      setFlags({ ...flags, detail: false })
      if (errorDetail) {
        dispatch(showAlert(handlerError(errorDetail.message)))
      } else {
        const provider: ICardProviderDTO = {
          provider: CardProviderOptions[0],
          authentication: detailResponse.authentication,
        }
        setShowProvider({ id: detailResponse.id, show: true, provider })
      }
    }
  }, [loadingDetail])

  const setUp = () => {
    dispatch(getProviders())
  }

  const onSelect = (provider: ICardProvider, see: boolean) => {
    if (see) {
      setShowProvider({ id: provider.id, show: false })
      dispatch(getProvider(provider.id))
    } else {
      dispatch(
        showAlert({
          ...handlerInfoWithButtons(
            'Eliminar proveedor',
            `¿Está seguro de eliminar al proveedor ${provider.name}?. Está acción no podrá ser revertida.`,
          ),
          onConfirm: () => dispatch(onDeleteConnection(provider.id)),
        }),
      )
    }
  }

  const onCreate = (connection: ICardProviderConnection) => {
    setShowProvider({ show: false })
    dispatch(onCreateConnection(connection))
  }

  return (
    <div>
      <Title title={'Proveedores de cobros con tarjeta'} />
      {canCreate && (
        <Row className={'container-buttons'}>
          <Button
            icon={faPlus}
            onClick={() => setShowProvider({ show: true })}
            loading={loadingCreate}>
            Agregar proveedor
          </Button>
        </Row>
      )}
      <Card white title={'Listado'}>
        <Row>
          <Col xl={12}>
            <CardProviderList onSelect={onSelect} loadingDelete={loadingDelete} />
          </Col>
        </Row>
      </Card>

      <CardProviderForm
        id={showProvider.id}
        show={showProvider.show}
        provider={showProvider.provider}
        onClose={() => setShowProvider({ show: false })}
        onChange={onCreate}
      />
    </div>
  )
}
