import Service from 'src/services/cardProvider.service'
import { executeAction } from './global.actions'

export const actions = {
  GET_CARD_PROVIDERS: 'GET_CARD_PROVIDERS',
  CREATE_CARD_CONNECTION: 'CREATE_CARD_CONNECTION',
  DELETE_CARD_CONNECTION: 'DELETE_CARD_CONNECTION',
  GET_CARD_PROVIDER: 'GET_CARD_PROVIDER',
  CREATE_LINK_PAYMENT: 'CREATE_LINK_PAYMENT',
  GET_LINK_PAYMENT_VALUES: 'GET_LINK_PAYMENT_VALUES',
  SET_LINK_PAYMENT_REQUEST: 'SET_LINK_PAYMENT_REQUEST',
  SET_CARD_PAYMENT_STATUS: 'SET_CARD_PAYMENT_STATUS',
  GET_CARD_PAYMENT_VOUCHER: 'GET_CARD_PAYMENT_VOUCHER',
}

export const getProviders = () => dispatch => {
  const process = async () => ({
    payload: await Service.getProviders(),
  })
  dispatch(executeAction(actions.GET_CARD_PROVIDERS, process))
}

export const onCreateConnection = (connection: ICardProviderConnection) => dispatch => {
  const process = async () => ({
    payload: await Service.onCreateConnection(connection),
  })
  dispatch(executeAction(actions.CREATE_CARD_CONNECTION, process))
}

export const onDeleteConnection = (providerType: number) => dispatch => {
  const process = async () => ({
    payload: await Service.onDeleteConnection(providerType),
  })
  dispatch(executeAction(actions.DELETE_CARD_CONNECTION, process))
}

export const getProvider = (providerType: number) => dispatch => {
  const process = async () => ({
    payload: await Service.getCardProvider(providerType),
  })
  dispatch(executeAction(actions.GET_CARD_PROVIDER, process))
}

export const setCardLinkPaymentRequest = (request: ICardLinkRequest) => dispatch => {
  const process = () => ({ payload: request })
  dispatch(executeAction(actions.SET_LINK_PAYMENT_REQUEST, process))
}

export const onCreateLinkPayment = (orderId: number) => dispatch => {
  const process = async () => ({
    payload: await Service.onCreateLinkPayment(orderId),
  })
  dispatch(executeAction(actions.CREATE_LINK_PAYMENT, process))
}

export const getLinkPayment = (order: string, uuid: string) => dispatch => {
  const process = async () => ({
    payload: await Service.getLinkPayment(order, uuid),
  })
  dispatch(executeAction(actions.GET_LINK_PAYMENT_VALUES, process))
}

export const setCardPaymentStatusRequest = (request: ICardLinkRequest) => dispatch => {
  const process = () => ({ payload: request })
  dispatch(executeAction(actions.SET_CARD_PAYMENT_STATUS, process))
}

export const getCardVoucher = (documentId: number) => dispatch => {
  const process = async () => ({
    payload: await Service.getVoucherByDocumentId(documentId),
  })
  dispatch(executeAction(actions.GET_CARD_PAYMENT_VOUCHER, process))
}
